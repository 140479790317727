import './cadUser.css';
import '../geral.css';
import React, { useEffect, useState } from 'react';
//import NavBar from '../../components/navbar';
//import DropProfissoes from '../../components/dropprofissoes';
//import DropEscolaridades from '../../components/dropescolariadade';
import User from '../../data/user';
import { Navigate, useNavigate } from 'react-router-dom';
//import DropTipoUser from './components/droptipouser.js';
import { useSelector, useDispatch } from 'react-redux';
//import ModalAviso from '../../components/avisos';
//import HeaderGeral from '../../components/header';
//import BottomGeral from '../../components/bottom';
//import FaixaGeral from '../../components/faixa';
import inMemoryJwt from '../../data/inMemoryJwt';
import { store } from '../../store/userStore';
import TipoUser from '../../data/tipouser';
import CampoTexto from './component/campotexto';
import CampoSwitch from './component/camposwitch';
import Combocategoriaprofissional from './component/combocategoriaprofissional';
import Combocor from './component/combocor';
import Comboorientacaosexual from './component/comboorientacaosexual';
import Comboatuacaoprofissional from './component/comboatuacaoprofissional';
import Comboformacao from './component/comboformacao';
import Comboinstrucao from './component/comboinstrucao';
import Combodeficiencia from './component/combodeficiencia';
import ModalAviso from '../../components/aviso';
import Navbar2 from '../../components/navbar2';

function CadUserScreen() {
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [datanascimento, setDataNascimento] = useState('1985-01-01');
    const [orientacao_sexual, setSexo] = useState(0);
    const [categoria_profissional, setCategoria_profissional] = useState(0);
    const [atuacao_profissional, setAtuacao_profissional] = useState(0);
    const [formacao, setFormacao] = useState(0);
    const [grauinstrucao, setGrauinstrucao] = useState(0);
    const [senha, setSenha] = useState('');
    const [telefone, setTelefone] = useState('');
    const [cor, setcor] = useState(0);
    const [coren, setcoren] = useState(0);
    const [setor, setsetor] = useState(0);
    const [anos_experiencia, setanos_experiencia] = useState(0);
    const [trabalha_outra_instituicao, settrabalha_outra_instituicao] = useState(0);
    const [professor, setprofessor] = useState(0);
    const [especializado, setespecializado] = useState(0);
    const [mestrado, setmestrado] = useState(0);
    const [doutorado, setdoutorado] = useState(0);
    const [capacitacao_previa, setcapacitacao_previa] = useState(0);
    const [experiencia_rcp, setexperiencia_rcp] = useState(0);
    const [importancia_rcp, setimportancia_rcp] = useState(0);
    const [dificuldade_aplicacao, setdificuldade_aplicacao] = useState(0);
    const [deficiencia, setdeficiencia] = useState(0);
    const [sucessoCadastro, setSucessoCadastro] = useState(false);
    const [deficienciaJson, setDeficienciaJson] = useState({
        tem: 0,
        baixavisao: 0,
        visual: 0,
        auditiva: 0,
        fisica: 0,
        transtorno_global: 0,
        outro: "outro",
        n_classificar: 0,
        n_responder: 0
    });



    const [erroNome, setErroNome] = useState('');
    const [statusPagina, setStatusPagina] = useState(0);
    const [statusCadastrar, setStatusCadastrar] = useState(0);
    const [redirecionar, setRedirecionar] = useState(false);


    const [email2, setEmail2] = useState('');
    const [senha2, setSenha2] = useState('');
    const [imagem, setImagem] = useState();
    const [imgPath, setImgPath] = useState();
    const [tipouser, setTipoUser] = useState(-1);
    const [senhasDiferentes, setSenhaDiferente] = useState();
    const [emailsDiferentes, setEmailsDiferentes] = useState();

    const [termos,setTermos] = useState(false);
    const [protecaodados,setProtecaoDados] = useState(false);
    const [msgModal, setMsgModal] = useState('');
    const [tituloModal, setTituloModal] = useState('');
    const [modalShow, setModalShow] = useState(false);

    var state = store.getState();

    /* useEffect(()=>{
        console.log(categoria_profissional);
    },[categoria_profissional]); */
    const [logged, setLogged] = useState(false);
    function refreshLogin(logado) {
        setLogged(logado);
    }

    function SetAnosExperiencia(valor) {
        if (valor < 0) {
            setanos_experiencia(0);
        } else {
            setanos_experiencia(valor);
        }
    }
    const erro = {
        nome: '',
        telefone: '',
        email: '',
        email2: '',
        datanascimento: '',
        orientacao_sexual: '',
        profissao: '',
        escolaridade: '',
        senha: '',
        senha2: '',
        tipouser: '',
        foto: '',
        coren: '',
        categoria_profissional: ''
    }
    const [erros, setErros] = useState(erro);
    const dispatch = useDispatch();
    async function cadastrar() {

        if (termos && protecaodados) {
            console.log('Iniciando cadatro');
            setStatusCadastrar(1);
            const data = Date.parse(datanascimento).valueOf() / 1000;
            let user = new User();
            //user.nome = nome;
            user.email = email;
            user.tipo = tipouser;
            user.orientacao_sexual = orientacao_sexual;
            user.datanascimento = data;
            user.senha = senha;
            user.anos_experiencia = anos_experiencia;
            user.capacitacao_previa = capacitacao_previa;
            user.categoria_profissional = categoria_profissional;
            user.cor = cor;
            //user.coren = coren;
            //user.deficiencia = deficiencia;
            user.dificuldade_aplicacao = dificuldade_aplicacao;
            user.doutorado = grauinstrucao;
            //user.especializado = especializado;
            user.experiencia_rcp = experiencia_rcp;
            //user.importancia_rcp = importancia_rcp;
            //user.mestrado = mestrado;
            user.orientacao_sexual = orientacao_sexual;
            //user.professor = professor;
            user.setor = atuacao_profissional;
            user.sexo = orientacao_sexual;
            user.telefone = telefone;
            user.deficienciaJson = deficienciaJson;
            //user.trabalha_outra_instituicao = trabalha_outra_instituicao;



            if (validar()) {
                console.log('Valido');
                if (imgPath == null) {
                    user.foto = 'logo.png';
                } else {
                    user.foto = "fotourl";
                }
                user.cadUser().then((resposta) => {
                    if (resposta.success > 0) {
                        user.loginJWT(user.email, user.senha).then(() => {
                            if (user.logged) {
                                dispatch({
                                    type: "SET",
                                    user: user
                                });
                            }
                            setSucessoCadastro(true);
                            setMsgModal('Cadastro efetuado com sucesso.');
                            setModalShow(true);
                        });

                        //limpar();
                    } else {
                        setTituloModal('Falha no cadastro');
                        setMsgModal(resposta.error);
                        setModalShow(true);
                    }
                }
                );
            }
            setStatusCadastrar(0);
        } else {
            if (!termos){
                setTituloModal('Termo de consentimento');
                setMsgModal("Caro usuário você deve aceitar nosso termo de consentimento.");
                setModalShow(true);
            } 
            if (!protecaodados){
                setTituloModal('Termo de consentimento da LGPD');
                setMsgModal("Caro usuário você deve aceitar nosso termo de consentimento da LGPD.");
                setModalShow(true);
            }
        }
    }
    function limpar() {
        setNome('');
        setTelefone('');
        setEmail('');
        setEmail2('');
        setSenha('');
        setSenha2('');
        setDataNascimento('1985-01-01');
    }
    function validar() {
        erro.nome = '';
        erro.telefone = '';
        erro.email = '';
        erro.email2 = '';
        erro.datanascimento = '';
        erro.profissao = '';
        erro.senha = '';
        erro.senha2 = '';
        erro.coren = '';
        erro.categoria_profissional = '';



        var valido = true;
        if (senha == null || senha == '') {
            erro.senha = 'Preencha a senha';
            valido = false;
        } else {
            if (senha !== senha2) {
                erro.senha = 'Senhas são diferentes';
                valido = false;
            }
        }
        if (email == null || email == '') {
            erro.email = 'Preencha o e-mail';
            valido = false;
        } else {
            if (email !== email2) {
                erro.email = 'Os e-mails são diferentes';
                valido = false;
            }
        }
        /* if (nome == null || nome == '') {
            erro.nome = 'Preencha o nome completo';
            valido = false;
        } */
        if (telefone == null || telefone == '') {
            erro.telefone = 'Preencha o telefone';
            valido = false;
        }
        /* if (datanascimento == null) {
            erro.datanascimento = 'Escolha uma data de nascimento';
            valido = false;
        } */
        /* if (coren == 0){
            erro.coren = 'Digite seu número do COREN';
            valido = false;
        } */
        if (categoria_profissional == 0) {
            erro.categoria_profissional = 'Escolha a Categoria Profissional';
            valido = false;
        }

        //if (imgPath == null) {
        //   erro.foto = 'Você deve selecionar uma foto';
        //   valido = false;
        //}

        setErros(erro);
        return valido;
    }
    function compararSenha(senhatemp) {
        var senha2 = '';
        senha2 = senha;
        var senhatemp2 = '';
        senhatemp2 = senhatemp;
        if (senha2 == null) {
            return;
        }
        if (senhatemp2 !== senha2.substring(0, senhatemp2.length)) {
            setSenhaDiferente(1);
        }
        else {
            setSenhaDiferente(0);
        }
        setSenha2(senhatemp);
    }
    let navigate = useNavigate();
    function finalizarCadastro() {
        setModalShow(false);
        if (sucessoCadastro) {
            navigate("/");
        }
    }
    function compararEmail(emailtemp) {
        if (email == null) {
            return;
        }
        var email2 = '';
        email2 = email;
        var emailtemp2 = '';
        emailtemp2 = emailtemp;
        if (emailtemp2 !== email2.substring(0, emailtemp2.length)) {
            setEmailsDiferentes(1);
        }
        else {
            setEmailsDiferentes(0);
        }
        setEmail2(emailtemp);
    }
    // function handleSelectProfissao(profissao) {
    //     setProfissao(profissao);

    // }
    // function handleSelectEscolaridade(escolaridade) {
    //     setEscolaridade(escolaridade);

    // }
    // function handleSexoChange(changeEvent) {
    //     setSexo(changeEvent.target.value);

    // }
    function print(evento) {
        console.log('clicou' + evento);
    }
    function handleSelectTipoUser(tipo) {
        setTipoUser(tipo);
    }
    if (redirecionar) {
        return <Navigate to='/' />;
    } else {
        return (
            <>

                <div className="">
                    <Navbar2 refreshLogin={() => refreshLogin()} />
                    <div className="jumbotron pb-5 pt-5">
                        <div className="container">
                            <h1 className="display-3 txt-primary">Programa de treinamento em emergências cardiovasculares para equipe de enfermagem</h1>
                            <p className="txt-primary">Faça aqui seu cadastro para participar da nossa pesquisa.</p>

                        </div>
                    </div>
                    {/* <HeaderGeral />
                    <NavBar />
                    <FaixaGeral titulo="Efetue Seu Cadastro" /> */}
                    <div className="row justify-content-center">



                        <div className="col-lg-6">
                            <div className="container mt-4 align-items-center">
                                {
                                    statusPagina === 0 ?
                                        <>

                                            <div className="Container">
                                                <form className="Form">
                                                    {/* <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <CampoTexto label="Seu nome completo" setValue={setNome} value={nome} erro={erros.nome} />
                                                            </div>
                                                        </div>

                                                    </div> */}
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <CampoTexto label="Endereço de e-mail" tipo="email" setValue={setEmail} value={email} erro={erros.email} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label htmlFor="emailinput2" className="txt-roxo">Repita o Endereço de e-mail</label>
                                                                <input type="email" className="form-control" id="emailinput2" onChange={(e) => compararEmail(e.target.value)} value={email2}></input>
                                                                {(emailsDiferentes === 1) ?
                                                                    <> <div className="alert alert-danger" role="alert">
                                                                        Os e-mails não são iguais!
                                                                    </div>
                                                                    </>
                                                                    :
                                                                    <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <CampoTexto label="Senha" tipo="password" setValue={setSenha} value={senha} erro={erros.senha} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label htmlFor="senhainput2" className="txt-roxo">Repita a senha</label>
                                                                <input type="password" className="form-control" id="senhainput2" onChange={(e) => compararSenha(e.target.value)} value={senha2}></input>

                                                                {(senhasDiferentes === 1) ?
                                                                    <> <div className="alert alert-danger" role="alert">
                                                                        As senhas não são iguais!
                                                                    </div>
                                                                    </>
                                                                    :
                                                                    <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <div className="form-group">
                                                                <CampoTexto label="Data de Nascimento" tipo="date" setValue={setDataNascimento} value={datanascimento} erro={erros.datanascimento} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <Combocategoriaprofissional label="Categoria profissional" value={categoria_profissional} setValue={setCategoria_profissional} erro={erros.categoria_profissional} />
                                                        </div>

                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <Combocor label={"Cor"} value={cor} setValue={setcor} />
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <Comboorientacaosexual label={"Gênero"} value={orientacao_sexual} setValue={setSexo} />
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <CampoTexto label="Telefone" setValue={setTelefone} value={telefone} erro={erros.telefone} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <Comboatuacaoprofissional label={"Atuação Profissional:"} value={atuacao_profissional} setValue={setAtuacao_profissional} />
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <Comboformacao label={"Formação:"} value={formacao} setValue={setFormacao} />
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <Comboinstrucao label={"Grau de instrução:"} value={grauinstrucao} setValue={setGrauinstrucao} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">

                                                            <CampoSwitch label="Sente algum grau de dificuldade para realização de RCP" setValue={setdificuldade_aplicacao} />
                                                            <CampoSwitch label="Já participou de algum atendimento de RCP?" setValue={setexperiencia_rcp} />
                                                            <CampoSwitch label="Possui capacitação prévia em RCP" setValue={setcapacitacao_previa} />
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="inputGroup-sizing-default">Quantos anos de experiência?</span>
                                                                <input type="number" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" onChange={(e) => SetAnosExperiencia(e.target.value)} value={anos_experiencia} />
                                                            </div>
                                                            <Combodeficiencia label="Possui alguma deficiência?" deficienciaJson={deficienciaJson} setDeficienciaJson={setDeficienciaJson} />
                                                        </div>
                                                    </div>
                                                    <div className='row mt-3'>
                                                        <div className='col'>
                                                            <a href='https://rcp.hbrsoftware.com.br/rcp-termo.pdf' target="_blank"><i class="fa-solid fa-download"/>Faça o download do Termo de Consentimento</a>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" value={termos} onClick={((checado)=>setTermos(checado))} id="termos_aceite" />
                                                                <label className="form-check-label" htmlFor="termos_aceite">
                                                                    Aceito o Termo de Consentimento para o estudo
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" value={protecaodados} onClick={(checado)=>setProtecaoDados(checado)} id="protecao_aceite"/>
                                                                <label className="form-check-label" htmlFor="protecao_aceite">
                                                                O usuário manifesta livre, incondicionada, informada e inequívoca AUTORIZAÇÃO e 
                                                                concorda com o tratamento dos seus dados pessoais pela equipe do projeto de pesquisa 
                                                                Avaliação do impacto de um programa de treinamento em emergências cardiovasculares 
                                                                para a equipe de enfermagem: um estudo quase-experimental, na forma da LGPD – Lei Geral de 
                                                                Proteção de Dados (Lei nº 13.709/2020). 
                                                                ATENÇÃO: A RECUSA NO CONSENTIMENTO IMPOSSIBILITA A 
                                                                PRESTAÇÃO DOS SERVIÇOS e/ou A PARTICIPAÇÃO NO PROGRAMA/EVENTO PRETENDIDO.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {
                                                        statusCadastrar === 1 ?
                                                            <>
                                                                <button type="button" className="btn btn-primary"><div className="spinner-border spinner-border-sm text-light" role="status">

                                                                </div></button>
                                                                <div className="flex mx-auto">
                                                                    <h6 className="txt-roxo">Cadastrando, Aguarde um segundo.</h6>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div className="div-bt text-center mt-3">
                                                                    <button type="button" className="btn btn-primary-out" onClick={cadastrar}>Cadastrar</button>
                                                                </div>
                                                            </>
                                                    }

                                                </form>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="container">
                                                <div className="col">
                                                    <div className="spinner spinner-border text-primary row" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    <span className="row">Cadastro efetuado com Sucesso!</span>
                                                    <span className="row">Agora você será redirecionado</span>
                                                </div>
                                            </div>
                                        </>

                                }
                            </div>
                        </div>
                    </div>
                </div>


                <ModalAviso msg={msgModal} titulo={tituloModal} show={modalShow} onHide={() => finalizarCadastro()} />
            </>
        );
    }
}

export default CadUserScreen;