import { useEffect, useState } from "react";
import Navbar2 from "../../components/navbar2";
import img_cpr from "../../image/cpr.png";
import { store } from "../../store/userStore";

function ObjetivosScreen() {
    var state = store.getState();
    const [logged, setLogged] = useState(state.user.logged);

    useEffect(() => {
        //inMemoryJwt.getTipo_user(state.user.id2).then(tipo => setTipoUser(tipo));
        //refreshToken(5);

        setLogged(state.user.logged);
    }, [logged]);

    function refreshLogin(logado) {
        setLogged(logado);
    }

    return (

        <>
            <Navbar2 refreshLogin={() => refreshLogin()} />

            <main>

                <section>
                    <div className="jumbotron pb-5 pt-5">
                        <div className="container">
                            <h1 className="display-3 txt-primary">Nossos Objetivos</h1>
                            <p className="txt-primary">A pesquisa busca contribuir com o hospital através da educação continuada ofertada aos profissionais da enfermagem aumentando o conhecimento acerca do atendimento em parada cardiorrespiratória, de forma a promover uma melhoria na assistência de enfermagem.</p>
                            <p>As melhorias serão inicialmente percebidas de forma gradual. À medida que formos treinando os profissionais, esperamos uma redução nos índices de mortalidade e/ou agravamento das emergências clínicas em um hospital. Temos como objetivos:</p>
                            <ul>
                                <li>Capacitar os profissionais de enfermagem de um hospital no atendimento em ressuscitação cardiopulmonar. </li>
                                <li>Avaliar o conhecimento teórico dos profissionais de enfermagem para o atendimento em ressuscitação cardiopulmonar.</li>
                                <li>Avaliar as habilidades técnicas dos profissionais de enfermagem durante o atendimento a um paciente em parada cardiorrespiratória (PCR) antes e após um programa de treinamento.</li>
                            </ul>
                            
                        </div>
                    </div>
                </section>
                <hr></hr>
            </main>
        </>
    );
}

export default ObjetivosScreen;