import React, { useEffect, useState } from "react";
import ModalAviso from "../../../../components/aviso";
import inMemoryJwt from "../../../../data/inMemoryJwt";
import {store} from "../../../../store/userStore.js"


function TilePergunta(props) {


    const [respMarcada,setRespMarcada] = useState([]);
    const [msgModal,setMsgModal] = useState('');
    const [tituloModal,setTituloModal] = useState('');
    const [showModal,setShowModal] = useState(false);
    const [respostasBin,setRespostasBin]= useState(0);
    const [mudou,setMudou] = useState(0);

    const [respMarcada0,setRespMarcada0] = useState(0);
    const [respMarcada1,setRespMarcada1] = useState(0);
    const [respMarcada2,setRespMarcada2] = useState(0);
    const [respMarcada3,setRespMarcada3] = useState(0);
    const total_respostas = 4;

    async function salvarResposta(resposta_check,index){
        

        var resp_aux = respostasBin;
        var resp_aux2 = respMarcada;
        console.log("resp aux"+resp_aux);
        props.setSalvando();
        setMudou(Date.now());
        if (resposta_check){
            var aux = 1;
            aux = 1 << index;
            resp_aux = resp_aux | aux;
            resp_aux2[index] = index;
            eval('setRespMarcada'+index+ '(1);');
        } else{
            var aux = 1;
            aux = 1 << index;
            resp_aux = resp_aux ^ aux;
            resp_aux2[index] = -1;
            eval('setRespMarcada'+index+ '(-1);');
        }
        console.log("depois resp aux"+resp_aux);
      
        
            setRespMarcada(resp_aux2);
            setRespostasBin(resp_aux);
        
        
        let resposta_json = await props.teste.updateResposta(props.numero_questao,resp_aux,props.id2,inMemoryJwt.getToken());
        props.setNaoSalvando();
        if (resposta_json.success != 1){
            setMsgModal("Suas respostas não estão sendo salvas.\n"+resposta_json.error);
            setTituloModal('Aviso');
            setShowModal(true);
        }
    }
    function teste(index,valor){

        return index==valor;
    }
    
    useEffect(()=>{
        var aux_respostas = [];
        var resp = props.resposta < 0 ? 0 : props.resposta;
        setRespostasBin(resp);
        for (var i=0; i<props.respostas.length;i++){
            //console.log("Resposta" + resp);
            if ((resp & 1) > 0){
                aux_respostas.push(i);
                eval('setRespMarcada'+i+ '(1);');
            }
            else {
                aux_respostas.push(-1);
                eval('setRespMarcada'+i+ '(-1);');
            }
            resp = resp >> 1;
        }
        //console.log("recarregando "+props.respostas.length);
        //console.log(props.resposta);
        setRespMarcada(aux_respostas);
    },[props.resposta,props.id2]);
    var state = store.getState();
    return (
        <>         
            <div className="card mt-2">
                <div className="card-body">
                    <h5 className="card-title" aria-disabled={true}>{props.pergunta}</h5>
                    
                        <div className="form-control">
                            {
                                
                        props.respostas.map((resposta,index) => {

                            return (
                                    
                                    <div className="form-check" key={index}>
                                        <input className="form-check-input" type="checkbox" name={"rb"+props.numero_questao} id={resposta.trim()} checked={eval('respMarcada'+index+ ' > 0;')} value={index} onChange={(e)=>salvarResposta(e.target.checked,index)} disabled={props.encerrado}/>
                                        <label className="form-check-label" htmlFor="exampleRadios1">
                                            {resposta}
                                        </label>
                                    </div>          
                            )
                        })
                        }
                        </div>
                    
                </div>
            </div>
            <ModalAviso msg={msgModal} titulo={tituloModal} show={showModal} onHide={() => setShowModal(false)} />
        </>
    );
}

export default TilePergunta;