import { url } from "../Utils";
import React from "react";
export default class Configs{
    static async getConfigs(){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({idUser: 0 })
        };
        
        const response = await fetch(url.concat('/utils/getConfigs.php'),requestOptions);
        const jsonresp = await response.json();
        if (jsonresp.success == 1){
            return jsonresp;
        } else{
            JSON.parse('{"success":0,"teste1Liberado": 0,"teste2Liberado": 0,"teste3Liberado": 0}');
        }

    }

    static async habilitarTeste1(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({idUser: idUser, jwt:jwt })
        };
        
        const response = await fetch(url.concat('/utils/setConfigsTeste1Habilitado.php'),requestOptions);
        const jsonresp = await response.json();
    }
    static async desabilitarTeste1(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({idUser: idUser, jwt:jwt })
        };
        
        const response = await fetch(url.concat('/utils/setConfigsTeste1Desabilitado.php'),requestOptions);
        const jsonresp = await response.json();
    }

    static async habilitarTeste2(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({idUser: idUser, jwt:jwt })
        };
        
        const response = await fetch(url.concat('/utils/setConfigsTeste2Habilitado.php'),requestOptions);
        const jsonresp = await response.json();
    }
    static async desabilitarTeste2(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({idUser: idUser, jwt:jwt })
        };
        
        const response = await fetch(url.concat('/utils/setConfigsTeste2Desabilitado.php'),requestOptions);
        const jsonresp = await response.json();
    }

    static async habilitarTeste3(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({idUser: idUser, jwt:jwt })
        };
        
        const response = await fetch(url.concat('/utils/setConfigsTeste3Habilitado.php'),requestOptions);
        const jsonresp = await response.json();
    }
    static async desabilitarTeste3(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({idUser: idUser, jwt:jwt })
        };
        
        const response = await fetch(url.concat('/utils/setConfigsTeste3Desabilitado.php'),requestOptions);
        const jsonresp = await response.json();
    }

    static async habilitarTreinamento(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({idUser: idUser, jwt:jwt })
        };
        
        const response = await fetch(url.concat('/utils/setConfigsTreinamentoHabilitado.php'),requestOptions);
        const jsonresp = await response.json();
    }

    static async desabilitarTreinamento(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({idUser: idUser, jwt:jwt })
        };
        
        const response = await fetch(url.concat('/utils/setConfigsTreinamentoDesabilitado.php'),requestOptions);
        const jsonresp = await response.json();
    }

    static async exportarTeste1(idUser,jwt){
        //console.log("pegfando");
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                "Expires": "Tue, 03 Jul 2001 06:00:00 GMT",
                "Cache-Control": "max-age=0, no-cache, must-revalidate, proxy-revalidate",
                "Last-Modified": "Thu, 19 May 2022 21:39:19 GMT",
                "Content-Type": "application/force-download",
                "Content-Type": "application/octet-stream",
                "Content-Type": "application/download",
                "Content-Disposition": "attachment;filename=data_export_2022-05-19.csv",
                "Content-Transfer-Encoding": "binary"


            },
            body: JSON.stringify({idUser: idUser, jwt:jwt })
        };
       
        //(await fetch(url.concat('/utils/exportarTeste1.php'),requestOptions)).blob;
        const response = await fetch(url.concat('/utils/exportarTeste1.php'),requestOptions);
        const resposta_str = await response.blob();
        return resposta_str;
        //const data = response.blob();
        //const fileDownloadUrl = URL.createObjectURL(blob);
        //console.log(blob);
        //URL.revokeObjectURL(fileDownloadUrl);
        //const jsonresp = await response.json();
    }

    static async exportarTeste2(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                "Expires": "Tue, 03 Jul 2001 06:00:00 GMT",
                "Cache-Control": "max-age=0, no-cache, must-revalidate, proxy-revalidate",
                "Last-Modified": "Thu, 19 May 2022 21:39:19 GMT",
                "Content-Type": "application/force-download",
                "Content-Type": "application/octet-stream",
                "Content-Type": "application/download",
                "Content-Disposition": "attachment;filename=data_export_2022-05-19.csv",
                "Content-Transfer-Encoding": "binary"


            },
            body: JSON.stringify({idUser: idUser, jwt:jwt })
        };
       
        const response = await fetch(url.concat('/utils/exportarTeste2.php'),requestOptions);
        const resposta_str = await response.blob();
        return resposta_str;
 
    }

    static async exportarTeste3(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                "Expires": "Tue, 03 Jul 2001 06:00:00 GMT",
                "Cache-Control": "max-age=0, no-cache, must-revalidate, proxy-revalidate",
                "Last-Modified": "Thu, 19 May 2022 21:39:19 GMT",
                "Content-Type": "application/force-download",
                "Content-Type": "application/octet-stream",
                "Content-Type": "application/download",
                "Content-Disposition": "attachment;filename=data_export_2022-05-19.csv",
                "Content-Transfer-Encoding": "binary"


            },
            body: JSON.stringify({idUser: idUser, jwt:jwt })
        };
       
        const response = await fetch(url.concat('/utils/exportarTeste3.php'),requestOptions);
        const resposta_str = await response.blob();
        return resposta_str;
 
    }

    static async exportarTestePratico(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                "Expires": "Tue, 03 Jul 2001 06:00:00 GMT",
                "Cache-Control": "max-age=0, no-cache, must-revalidate, proxy-revalidate",
                "Last-Modified": "Thu, 19 May 2022 21:39:19 GMT",
                "Content-Type": "application/force-download",
                "Content-Type": "application/octet-stream",
                "Content-Type": "application/download",
                "Content-Disposition": "attachment;filename=data_export_2022-05-19.csv",
                "Content-Transfer-Encoding": "binary"


            },
            body: JSON.stringify({idUser: idUser, jwt:jwt })
        };
       
        const response = await fetch(url.concat('/utils/exportarTestePratico.php'),requestOptions);
        const resposta_str = await response.blob();
        return resposta_str;
 
    }

    static async exportarTestePratico2(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                "Expires": "Tue, 03 Jul 2001 06:00:00 GMT",
                "Cache-Control": "max-age=0, no-cache, must-revalidate, proxy-revalidate",
                "Last-Modified": "Thu, 19 May 2022 21:39:19 GMT",
                "Content-Type": "application/force-download",
                "Content-Type": "application/octet-stream",
                "Content-Type": "application/download",
                "Content-Disposition": "attachment;filename=data_export_2022-05-19.csv",
                "Content-Transfer-Encoding": "binary"


            },
            body: JSON.stringify({idUser: idUser, jwt:jwt })
        };
       
        const response = await fetch(url.concat('/utils/exportarTestePratico2.php'),requestOptions);
        const resposta_str = await response.blob();
        return resposta_str;
 
    }

    static async exportarTestePratico3(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                "Expires": "Tue, 03 Jul 2001 06:00:00 GMT",
                "Cache-Control": "max-age=0, no-cache, must-revalidate, proxy-revalidate",
                "Last-Modified": "Thu, 19 May 2022 21:39:19 GMT",
                "Content-Type": "application/force-download",
                "Content-Type": "application/octet-stream",
                "Content-Type": "application/download",
                "Content-Disposition": "attachment;filename=data_export_2022-05-19.csv",
                "Content-Transfer-Encoding": "binary"


            },
            body: JSON.stringify({idUser: idUser, jwt:jwt })
        };
       
        const response = await fetch(url.concat('/utils/exportarTestePratico3.php'),requestOptions);
        const resposta_str = await response.blob();
        return resposta_str;
 
    }
}