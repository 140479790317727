function Comboorientacaosexual(props) {
    return (
        <>
        <label htmlFor={"cmb"+props.label.trim()} className="txt-roxo">{props.label}</label>
        <select className="form-select" aria-label="Default select example" id={"cmb"+props.label.trim()}  value={props.value} onChange={(e) => props.setValue(e.target.value)}>
            <option value={0}>Não desejo informar</option>
            <option value={1}>Masculino</option>
            <option value={2}>Feminino</option>
        </select>
        </>
    );
}

export default Comboorientacaosexual;