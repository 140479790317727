import { url } from "../Utils";

export default class TestePratico{
    perguntas = [];
    respostas = new Map();
    mudou = 0;
    encerrado = 0;

    constructor(){
        
    }

    addResposta(questao,resposta) {
        this.respostas.set(questao,resposta);
        const obj = Object.fromEntries(this.respostas);
        //console.log(obj);
    }

    async updateResposta(numero_questao,resposta,idUser,jwt){
        this.respostas.set(numero_questao,resposta);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({resposta: resposta, numero_questao:numero_questao.toString(),idUser:idUser,jwt:jwt })
        };
        const response = await fetch(url.concat('/teste/updateRespostaTestePratico.php'),requestOptions);
        const jsonresp = await response.json();
       
        return jsonresp;
    }

    async getRespostaUser(idUser,jwt,idUserTeste){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({idUser:idUser,jwt:jwt,idUserTeste:idUserTeste })
        };
        const response = await fetch(url.concat('/teste/getRespostaTestePratico.php'),requestOptions);

        const jsonresp = await response.json();
        const jsonrespostas = await JSON.parse(jsonresp.respostas);

        this.encerrado = jsonresp.encerrado;

        for (var resposta in jsonrespostas){
            
            this.respostas.set(resposta.toString(),jsonrespostas[resposta]);

        }
        this.mudou++;
        return jsonresp;
    }

    async encerrarTeste(idUser,idAvaliado,jwt,obs){
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({idUser:idUser,idAvaliado:idAvaliado,jwt:jwt,respostas: JSON.stringify(Object.fromEntries(this.respostas)),obs:obs})
        };
        
        const response = await fetch(url.concat('/teste/finalizarTestePratico.php'),requestOptions);
        //console.log(response);
        const jsonresp = await response.json();
        return jsonresp;
    }

    async getPerguntasTestePratico(){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: ""
            })
        };
        const response = await fetch(url.concat('/teste/getPerguntasTestePratico.php'),requestOptions);
        const jsonresp = await response.json();
        var indice = 1;
        var perguntas = [];
        while (jsonresp["p"+indice] !== undefined){
            var indice2 = 1;
            var itens_escolha = [];
            while (jsonresp["p"+indice].escolhas["item"+indice2] != undefined){
                itens_escolha.push(jsonresp["p"+indice].escolhas["item"+indice2]);
                indice2++;
            }
            perguntas.push(
                {
                    pergunta: jsonresp["p"+indice].titulo,
                    id: indice.toString(),
                    respostas: itens_escolha
                }
            );
            indice++;
        }
        return perguntas;
    }
}