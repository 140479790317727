import './videotreinamento.css';
import { useEffect, useState } from "react";

import { store } from "../../store/userStore";
import Navbar2 from '../../components/navbar2';

function VideoTreinamento(props) {

    var state = store.getState();
    const [logged, setLogged] = useState(state.user.logged);

    useEffect(() => {
        //inMemoryJwt.getTipo_user(state.user.id2).then(tipo => setTipoUser(tipo));
        //refreshToken(5);

        setLogged(state.user.logged);
    }, [logged]);

    function refreshLogin(logado) {
        setLogged(logado);
    }
    return (
        <>
            <Navbar2 refreshLogin={() => refreshLogin()} />
            <div className="jumbotron pb-5 pt-5">
                    <div className="container">
                        <h1 className="display-3 txt-primary">RCP!</h1>
                        <p className="txt-primary">Treinamento.</p>
                       
                    </div>
                </div>
            <div className="row text-center">
                <div className="col">
                    <div className="container">
                        <h3>Treinamento</h3>
                        <div className="video-responsive">
                            <iframe
                                width="977"
                                height="550"
                                src="https://www.youtube.com/embed/I22556zAKKY"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen>

                            </iframe>


                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VideoTreinamento;