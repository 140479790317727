import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import inMemoryJwt from '../../data/inMemoryJwt';
import User from '../../data/user';
import { store } from '../../store/userStore';
import { Link, useNavigate } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import LoginScreen from '../../view/login';
import { propTypes } from 'react-bootstrap/esm/Image';
import './navbar2.css';
import img_cpr from "../../image/cpr.png"
export default function Navbar2(props) {

    const [modalShow, setModalShow] = useState(false);
    const [loginModal, setLoginModal] = useState(false);

    let navigate = useNavigate();
    var state = store.getState();
    const dispatch = useDispatch();
    const [logged, setLogged] = useState(state.user.logged);
    const [jwtExpirado, setJwtExpirado] = useState(false);
    const [tipoUser, setTipoUser] = useState(-1);
    const [nomeUser, setNomeUser] = useState('');
    let jwtValido;


    const refreshToken = (delay) => {
        jwtValido = window.setTimeout(
            getRefreshedToken,
            delay * 1000 - 5000
        ); // Validity period of the token in seconds, minus 5 seconds
    };
    function logout() {
        inMemoryJwt.setTipo_user(-1);
        inMemoryJwt.ereaseToken();

        //state.user.logout();

        dispatch({
            type: "SAIR",
            user: new User('anonimo', 'email', 0)
        });
        state = store.getState();
        setLogged(state.user.logged);
        //navigate.push("/");
    }
    const getRefreshedToken = () => {
        if (inMemoryJwt.getToken()) {
            setJwtExpirado(false);
            refreshToken(30);
        } else {
            setJwtExpirado(true);
        }
    }
    useEffect(() => {
        inMemoryJwt.getTipo_user(state.user.id2).then(tipo => setTipoUser(tipo));
        refreshToken(5);
        getRefreshedToken();
        props.refreshLogin(logged);
        setNomeUser(state.user.nome);
        //console.log('logado '+tipoUser);
    }, [logged]);

    useEffect(() => {
        inMemoryJwt.getTipo_user(state.user.id2).then(tipo => setTipoUser(tipo));
        refreshToken(5);
    }, [inMemoryJwt.getToken()]);

    return (
       
        <>
            <div className="container2">
                <div className="row">
                    <div className="col-12">
                        {jwtExpirado ?
                            <div className="alert alert-primary alert-dismissible mt-2 text-center" role="alert">
                                Caro Usuário seu login expirou, <strong onClick={() => setLoginModal(true)} className="linkloginaviso">clique aqui</strong> para refazer seu login.
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            :
                            <></>
                        }
                    </div>
                </div>
                <nav className="navbar navbar-expand-sm navbar-light bg-light">
                    <a className="navbar-brand ms-2" href="#">
                        <img src={img_cpr} width="30" height="30" className="d-inline-block align-top" alt="" />
                        RCP
                    </a>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item active">
                               <Link className='nav-link nolink' to="/">Página Inicial</Link>
                            </li>
                            <li className="nav-item active">
                               <Link className='nav-link nolink' to="/quemsomos">Quem Somos</Link>
                            </li>
                            <li className="nav-item active">
                               <Link className='nav-link nolink' to="/objetivos">Nossos Objetivos</Link>
                            </li>
                            <li className="nav-item active">
                               <Link className='nav-link nolink' to="/apresentacao">Orientações aos Voluntários</Link>
                            </li>
                            <li className="nav-item">
                                 <Link className='nav-link nolink' to="/treinamento">Área do Aluno</Link>
                            </li>
                            
                            {
                                    tipoUser == 1 ||  tipoUser == 2 ?
                                    <li className="nav-item">
                                    <Link className='nav-link nolink' to="/findaluno">Área do Pesquisador</Link>
                                    </li>
                                        :
                                        <></>
                            }
                            <li className='nav-item'>
                                {
                                    !state.user.logged ?
                                        <>
                                            <button className="btn btn-primary d-inline-block" onClick={() => setLoginModal(true)}>Entrar<i className="ms-2 fa-solid fa-arrow-right-to-bracket"></i></button>

                                        </>
                                        :
                                        <button className="btn btn-primary" onClick={logout}>Sair<i className="ms-2 fa-solid fa-arrow-right-from-bracket"></i></button>

                                }
                            </li>
                            {
                                
                                    !state.user.logged ? 
                                    <li className='nav-item ms-1'>
                                    <Link to="/caduser"><button className="btn btn-primary d-inline-block" >Cadastro<i className="ms-2 fa-solid fa-user-pen"></i></button></Link>
                                    </li>
                                    :
                                    <></>
                                
                            }
                            
                            
                        </ul>
                        <span className='navbar-text'>
                            {
                                    tipoUser == 1 ?
                                        <Link className='nolink' to="/adminpage"><button className="btn btn-primary ms-sm-1">Admin<i className="ms-2 fa-solid fa-gears"></i></button></Link>
                                        :
                                        <></>
                                }
                            </span>
                    </div>
                </nav>

            </div>
            <Modal
                show={loginModal}
                onHide={() => setLoginModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titulo-modal mx-auto">Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LoginScreen fecharModal={() => setLoginModal(false)} setLogged={() => setLogged(true)} />
                </Modal.Body>
            </Modal>

        </>
    );
}