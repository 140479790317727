import './App.css';
import {BrowserRouter, Route,Routes} from 'react-router-dom';
import TelaInicial from './view/inicial';
import CadUser from './view/cadUser';
import { Provider } from 'react-redux';
import {store,persistor} from '../src/store/userStore';
import { PersistGate } from 'redux-persist/integration/react';
import Teste1 from './view/teste1';
import AdminPage from './view/admin_page';
import VideoTreinamento from './view/video_treinamento';
import TesteView2 from './view/teste2';
import Treinamento from './view/treinamento';
import Apresentacao from './view/apresentacao';
import QuemSomosScreen from './view/quemsomos';
import ObjetivosScreen from './view/objetivos';
import ApresentacaoDetalhesScreen from './view/apresentacaodetalhes';
import TesteView3 from './view/teste3';
import TestePratico from './data/teste_pratico';
import FindAlunoView from './view/find_aluno';
import CadAdminScreen from './view/cad_admin';
function App() {
  return(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Apresentacao/>}/>
            <Route path='/Treinamento' element={<Treinamento/>}/>
            <Route path='/caduser' element={<CadUser/>}/>
            <Route path='/cadadmin' element={<CadAdminScreen/>}/>
            <Route path='/teste1' element={<Teste1/>}/>
            <Route path='/teste2' element={<TesteView2/>}/>
            <Route path='/teste3' element={<TesteView3/>}/>
            <Route path='/adminpage' element={<AdminPage/>}/>
            <Route path='/findaluno' element={<FindAlunoView/>}/>
            <Route path='/treinamento_testepratico' element={<TestePratico/>}/>
            <Route path='/videotreinamento' element={<VideoTreinamento/>}/>
            <Route path='/quemsomos' element={<QuemSomosScreen/>}/>
            <Route path='/objetivos' element={<ObjetivosScreen/>}/>
            <Route path='/apresentacao' element={<ApresentacaoDetalhesScreen/>}/>
          </Routes>  
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
