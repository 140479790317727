function CampoTexto(props) {
    return (
        <>
            <label htmlFor={props.label.trim()} className="txt-roxo">{props.label}</label>
            <input type={props.tipo || "text"} className="form-control required" id={props.label.trim()} onChange={(e) => props.setValue(e.target.value)} value={props.value}></input>
            {props.erro !== '' ?
                <> <div className="alert alert-danger" role="alert">
                    {props.erro}
                </div> </>
                :
                <></>
            }
        </>
    );
}

export default CampoTexto;