import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Navbar from "../../components/navbar";
import Navbar2 from "../../components/navbar2";
import inMemoryJwt from "../../data/inMemoryJwt";
import User from "../../data/user";
import { store } from "../../store/userStore";
import { Link, useNavigate } from "react-router-dom";
import TestePratico from "../../data/teste_pratico";
import TestePratico2 from "../../data/teste_pratico2";
import TestePratico3 from "../../data/teste_pratico3";
import TilePergunta from "./components/tilepergunta";
import ModalAviso from "../../components/aviso";
import ModalEscolha from "../../components/modalEscolha";
import ModalSalvando from "../../components/modal_salvando";
function FindAlunoView() {

    var state = store.getState();

    const [mostrarAviso, setMostraAviso] = useState(false);
    const [mostrarEscolha, setMostraEscolha] = useState(false);
    const [modalTitulo, setTituloModal] = useState('');
    const [modalMSG, setModalMSG] = useState('');
    const [respostaHook, setRespostas] = useState(new Map());
    const [logged, setLogged] = useState(state.user.logged);
    const [email, setEmail] = useState('');
    const [nome_avaliado, setNomeAvaliado] = useState("");
    const [testes, setTeste] = useState(new TestePratico());
    const [encerrado, setEncerrado] = useState(0);
    const [respostasCarregadas, setRespostasCarregadas] = useState(true);
    const [perguntas, setPerguntas] = useState([]);
    const [avaliado,setAvaliado] =useState(new User());
    const [obs,setObs] = useState('');
    const [id2Avaliado,setId2Avaliado] = useState('-1');
    const [iniciar,setIniciar] = useState(false);
    const [tituloTeste,setTituloTeste] = useState('');
    const [testeAtual,setTesteAtual] = useState(1);
    const [salvando,setSalvando] = useState(false);
    function refreshLogin(logado) {
        setLogged(logado);
    }

    function getUser(email) {
        var avaliado2 = new User();
        setIniciar(false);
        avaliado2.getByEmail(email).then(() => {
            setNomeAvaliado(avaliado2.nome);
            setId2Avaliado(avaliado2.id2);
            setAvaliado(avaliado2);
            
        });
        
    }
    
    var encerrarTeste = () => {
        setMostraEscolha(false);
        testes.encerrarTeste(state.user.id2,id2Avaliado, inMemoryJwt.getToken(),obs).then((resposta) => {
            setEncerrado(parseInt(resposta.success));
            if (parseInt(resposta.success) === 1) {
                setTituloModal('Aviso');
                setModalMSG('Seu teste foi encerrado a partir desse momento você não pode mais modificá-lo');
                setMostraAviso(true);
            } else {
                //console.log(resposta);
                setTituloModal('Aviso');
                setModalMSG('Seu teste NÃO foi encerrado e nem suas respostas foram salvas. ' + resposta.error);
                setMostraAviso(true);
            }
        });
    }
    var mostrarAvisoEncerrar = () => {
        setTituloModal('Aviso');
        setModalMSG('Se você clicar em encerrar você não poderá mais responder ao teste. Deseja mesmo encerrar?');
        setMostraEscolha(true);
    }

    useEffect(() => {
        getPerguntas(testeAtual);
    }, [logged,testeAtual]);

   

    function getPerguntas(numero_teste){
        if (numero_teste === undefined){
            numero_teste = 1;
        }
        setTesteAtual(numero_teste);
        if (numero_teste === 1){
            var testes2 =  new TestePratico();
            setTituloTeste("Inicial");
        } else {
            
            if (numero_teste === 2){
                setTituloTeste("Imediato");
                var testes2 =  new TestePratico2();
            } else{
                setTituloTeste("Final");
                var testes2 =  new TestePratico3();
            }
        }
       
        setTeste(testes2);
        testes2.getRespostaUser(state.user.id2, inMemoryJwt.getToken(),id2Avaliado).then((_json_resp) => {
            setRespostas(testes2.respostas);
            setEncerrado(testes2.encerrado);
            setTeste(testes2);
            setRespostasCarregadas(true);
            setLogged(state.user.logged);
            setIniciar(true);
            if (_json_resp.success != 1) {
                setRespostasCarregadas(false);
            }
        });
        testes.getPerguntasTestePratico().then((_json) => {
            setPerguntas(_json);

        });
    }
    function getResposta(index) {
        if (typeof index === 'undefined') {
            return -1;
        } else {
            return respostaHook.get(index.toString()) == undefined ? 0 : respostaHook.get(index.toString());
        }
    }

    return (
        <>
            <Navbar2 refreshLogin={() => refreshLogin()} />
            <div className=" container2 p-2">
                <div className="row">
                    <div className="col">
                        <h1>Buscar Aluno</h1>
                        <div className="card">
                            <h5 className="card-header">Entre com e-mail do avaliado.</h5>
                            <div className="card-body">
                                <h5 className="card-title">e-mail</h5>
                                <p className="card-text">Faça a busca do avaliado pelo e-mail cadastrado.</p>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">@</span>
                                    <input type="text" onChange={(e) => setEmail(e.target.value)} value={email} className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                                </div>
                                {
                                    true ?
                                        <a className="btn btn-primary" onClick={() => getUser(email)}>Buscar</a>
                                        :
                                        <a className="btn btn-primary" onClick={() => ({})}>Habilitar</a>
                                }

                                <div>
                                    <h4>Avaliado:</h4>
                                    <h5>{nome_avaliado}</h5>
                                    
                                </div>
                                <>
                                {
                                    id2Avaliado == -1 ?
                                    <></>
                                    :
                                    <div>
                                    <button className="btn btn-primary" onClick={() =>getPerguntas(1)}>Iniciar Testre Prático Inicial</button>
                                    <button className="btn btn-primary" onClick={() =>getPerguntas(2)}>Iniciar Testre Prático Imediato</button>
                                    <button className="btn btn-primary" onClick={() =>getPerguntas(3)}>Iniciar Testre Prático Final</button>
                                    {
                                        iniciar ?
                                        <>
                                        <div className="jumbotron pb-5 pt-5">
                                        <div className="container">
                                            <h1 className="display-3 txt-primary">{"Teste Prático "+tituloTeste}</h1>
                                            <p className="txt-primary"></p>

                                        </div>
                                    </div>
                                    <div className="container2">
                                        {
                                            respostasCarregadas ? <></> :
                                                <div className="alert alert-primary alert-dismissible mt-2 text-center" role="alert">
                                                    Suas respostas não foram carregadas, por favor refaça seu login.
                                                </div>

                                        }
                                        {
                                            perguntas.map((pergunta, index) => {
                                                return <TilePergunta teste={testes}
                                                setSalvando={()=>setSalvando(true)} 
                                                setNaoSalvando={()=>setSalvando(false)}
                                                pergunta={pergunta.pergunta} 
                                                respostas={pergunta.respostas} 
                                                key={index + 500} 
                                                numero_questao={index} 
                                                resposta={getResposta(index)}
                                                id2={id2Avaliado}
                                                encerrado={parseInt(encerrado) == 1 || !logged} />
                                            })
                                        }
                                        <div className="card mt-4 p-2">
                                            <div className="form-group">
                                            <label htmlFor="obs" className="txt-roxo">Observações</label>
                                            <input className="form-control" id="senhainput2" 
                                            onChange={e => setObs(e.target.value)} value={obs}
                                            disabled={parseInt(encerrado) == 1 || !logged}
                                            />
                                            </div>
                                        </div>
                                        <button className="btn btn-primary" onClick={mostrarAvisoEncerrar} disabled={parseInt(encerrado) == 1 || !logged}>Encerrar Teste</button>
                                        <ModalAviso show={mostrarAviso} titulo={modalTitulo} msg={modalMSG} onHide={() => setMostraAviso(false)} />
                                        <ModalEscolha show={mostrarEscolha} titulo={modalTitulo} msg={modalMSG} onHide={() => setMostraEscolha(false)} sim={encerrarTeste} nao={() => setMostraEscolha(false)} />
                                        <ModalSalvando show={salvando} titulo={"Salvando Respostas"} msg={"Aguarde Salvando...."} onHide={() => setSalvando(false)} />
                                    </div>
                                
                                        </>
                                        :
                                        <></>
                                    }
                                    </div>
                                    
                                }
                                </>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );

}
export default FindAlunoView;