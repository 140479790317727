import "./treinamento.css"
import React, { useState, useEffect } from 'react';
import ModalAviso from "../../components/aviso";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import LoginScreen from "../login";
import img_teste from "../../image/test.png";
import img_computer from "../../image/computer.png";
import img_education from "../../image/education.png";
import { store } from "../../store/userStore";
import inMemoryJwt from "../../data/inMemoryJwt";
import { useDispatch } from 'react-redux';
import User from "../../data/user";
import Configs from "../../data/configs";
import Navbar2 from "../../components/navbar2";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
function Treinamento(props) {


    const [modalShow, setModalShow] = useState(false);
    const [loginModal, setLoginModal] = useState(false);
    const [msgModal,setMsgModal] = useState('');

    const [teste1Liberado, setTeste1Liberado] = useState(0);
    const [teste2Liberado, setTeste2Liberado] = useState(0);
    const [teste3Liberado, setTeste3Liberado] = useState(0);
    const [treinamentoLiberado, setTreinamentoLiberado] = useState(0);
    const [updatePag, setupdatePag] = useState(0);

    let navigate = useNavigate();
    var state = store.getState();
    const dispatch = useDispatch();
    const [logged, setLogged] = useState(state.user.logged);

    const [email, setEmail] = useState();
    const [senha, setSenha] = useState();
    const [falhaLogin, setFalhaLogin] = useState(false);


    function refreshLogin(logado) {
        setLogged(logado);
    }

    async function login() {
        let user = new User();

        await user.loginJWT(email, senha);
        if (user.logged) {
            dispatch({
                type: "SET",
                user: user
            });
            setLogged(true);
            //props.fecharModal();
            setFalhaLogin(false);
        } else {
            setFalhaLogin(true);
        }
    }
    function cadastrar() {

        navigate("/caduser", { replace: true });
    }
    
    function showModal() {

        setModalShow(true);
    }
    function logout() {
        inMemoryJwt.setTipo_user(-1);
        inMemoryJwt.ereaseToken();

        //state.user.logout();

        dispatch({
            type: "SAIR",
            user: new User('anonimo', 'email', 0)
        });
        state = store.getState();
        setLogged(state.user.logged);

        //navigate.push("/");
    }
    useEffect(() => {
        //inMemoryJwt.getTipo_user(state.user.id2).then(tipo => setTipoUser(tipo));
        //refreshToken(5);

        setLogged(state.user.logged);
    }, [logged]);



    useEffect(() => {
        Configs.getConfigs().then((retorno) => {
            setTeste1Liberado(retorno.teste1Liberado);
            setTeste2Liberado(retorno.teste2Liberado);
            setTeste3Liberado(retorno.teste3Liberado);
            setTreinamentoLiberado(retorno.treinamentoLiberado);
        });
    }, [updatePag]);

    

    function recuperarsenha() {
        if (email == null || email === '') {
            setMsgModal("Por favor preencha o e-mail desejado para recuperação. Obrigado.");
            setModalShow(true);
            return;
        }

        var user = new User();
        user.recuperarSenha(email).then((resultado) => {
            if (resultado === 1) {
                setMsgModal("E-mail enviado com sucesso para " + email + ", por favor verifique sua caixa de e-mail caso não esteja lá verifique também a caixa de spam. Obrigado.");
                setModalShow(true);
            } else if (resultado === -1) {
                setMsgModal("O e-mail " + email + " não foi encontrado na nossa base de dados. Por favor verifique se o e-mail está correto. Obrigado.");
                setModalShow(true);
            }
        })
    }

    return (

        <>

            <Navbar2 refreshLogin={() => refreshLogin()} />
            <div className="container2   bg-dark">
                <div className="row justify-content-center flex-sm-row">
                    <div className="col">
                        <div className="jumbotron p-3 p-md-5 text-black rounded bg-dark">
                            <div className="col-md-6 px-0">
                                <h1 className="display-4 font-italic">Programa de treinamento em emergências cardiovasculares para equipe de enfermagem</h1>
                                {/* <p className="lead my-3">Sejam bem-vindos ao treinamento de suporte básico de vida em adultos, proveniente de um projeto de pesquisa intitulado Avaliação do impacto de um programa de treinamento em emergências cardiovasculares para a equipe de enfermagem: um estudo quase-experimental.</p> */}

                            </div>
                        </div>
                    </div>


                </div>
                {
                    logged ?
                        <div>

                            <VerticalTimeline layout={'1-column-left'} lineColor="#f7ede2">
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    iconStyle={{ background: 'rgb(255,255, 255)', color: '#fff' }}
                                    icon={<img src={img_teste} width="27" height="27" className="image-center" />}
                                >
                                    <h3 className="vertical-timeline-element-title">Teste Inicial</h3>
                                    <p>
                                        Começe seus estudos fazendo nosso teste inicial.
                                    </p>
                                    {
                                        logged ?
                                            teste1Liberado ?
                                                <Link className="nolink" to="/teste1"><button className="btn btn-primary botao-card align-bottom">Iniciar</button></Link>
                                                :
                                                <button className="btn btn-primary botao-card align-bottom">Aguarde a liberação</button>
                                            :
                                            <button className="btn btn-primary botao-card align-bottom">Faça Login</button>
                                    }
                                </VerticalTimelineElement>

                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    iconStyle={{ background: 'rgb(255,255, 255)', color: '#fff' }}
                                    icon={<img src={img_computer} width="28" height="28" className="image-center" />}
                                >
                                    <h3 className="vertical-timeline-element-title">Treinamento</h3>
                                    <p>
                                        Assista nosso treinamento.
                                    </p>
                                    {
                                        logged ?
                                            treinamentoLiberado ?
                                                <Link className="nolink" to="/videotreinamento"><button className="btn btn-primary botao-card">Iniciar</button></Link>
                                                :
                                                <button className="btn btn-primary botao-card">Aguarde a liberação</button>
                                            :
                                            <button className="btn btn-primary botao-card">Faça Login</button>
                                    }
                                </VerticalTimelineElement>

                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    iconStyle={{ background: 'rgb(255,255, 255)', color: '#fff' }}
                                    icon={<img src={img_teste} width="28" height="28" className="image-center" />}
                                >
                                    <h3 className="vertical-timeline-element-title">Teste Imediato</h3>
                                    <p>
                                        Teste imediato após o treinamento.
                                    </p>
                                    {
                                        logged ?
                                            teste3Liberado ?
                                                <Link className="nolink" to="/teste3"><button className="btn btn-primary botao-card align-bottom">Iniciar</button></Link>
                                                :
                                                <button className="btn btn-primary botao-card align-bottom">Aguarde a liberação</button>
                                            :
                                            <button className="btn btn-primary botao-card align-bottom">Faça Login</button>
                                    }
                                </VerticalTimelineElement>
                                
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    iconStyle={{ background: 'rgb(255,255, 255)', color: '#fff' }}
                                    icon={<img src={img_teste} width="28" height="28" className="image-center" />}
                                >
                                    <h3 className="vertical-timeline-element-title">Pós Teste</h3>
                                    <p>
                                        Pós-teste 30 dias após o treinamento.
                                    </p>
                                    {
                                        logged ?
                                            teste2Liberado ?
                                                <Link className="nolink" to="/teste2"><button className="btn btn-primary botao-card align-bottom">Iniciar</button></Link>
                                                :
                                                <button className="btn btn-primary botao-card align-bottom">Aguarde a liberação</button>
                                            :
                                            <button className="btn btn-primary botao-card align-bottom">Faça Login</button>
                                    }
                                </VerticalTimelineElement>
                            </VerticalTimeline>
                        </div>
                        :
                        <>

                            <div className="container mt-5">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mx-auto">
                                            <form className="form-login mx-auto">
                                                <h2 className="d-flex flex-row justify-content-center">Entrar</h2>
                                                <div className="form-group input-icons">
                                                    <i className="fas fa-envelope icon"></i>
                                                    <input
                                                        type="email"
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        className="form-control input-field mx-auto"
                                                        placeholder="Digite o email"
                                                    />
                                                </div>
                                                <div className="form-group input-icons">
                                                    <i className="fas fa-key icon"></i>
                                                    <input
                                                        type="password"
                                                        onChange={(e) => setSenha(e.target.value)}
                                                        className="form-control input-field"
                                                        placeholder="Digite a senha"
                                                    />
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={login}
                                                >
                                                    Entrar
                                                </button>
                                                
                                                {
                                                    falhaLogin ?
                                                        <div className="txtfalha d-flex justify-content-center">
                                                            Usuário ou senha inválidos
                                                        </div>
                                                        : <></>
                                                }
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <h2>Esqueceu a senha?</h2>
                                        <p>Não tem problema. Entre com e-mail abaixo para que seja enviada uma nova senha para seu e-mail. Não esqueça de olhar na caixa de spam. </p>
                                        <form>
                                            <div className="form-group">
                                                <label htmlFor="emailinput" className="txt-roxo">Entre com o email cadastrado</label>
                                                <input type="email" className="form-control required" id="emailinput" onChange={(e) => setEmail(e.target.value)} placeholder="exemplo@servidor.com"></input>
                                            </div>
                                            <button type="button" className="btn btn-primary" onClick={recuperarsenha}>Recuperar</button>
                                        </form>
                                        
                                    </div>
                                    <div className="col-md-4">
                                        <h2>Cadastro</h2>
                                        <p>Ainda não tem cadastro? Clique em cadastrar e faça o seu cadastro para para ter acesso aos nosso recursos.</p>
                                        <button type="button" className="btn btn-primary" onClick={cadastrar}>Cadastrar</button>
                                    </div>
                                </div>

                            </div>
                        </>
                }
            </div>
            <ModalAviso msg={msgModal} titulo={"Aviso"} show={modalShow} onHide={() => setModalShow(false)} />

        </>
    );
}

export default Treinamento;