import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Teste3 from "../../data/teste3";
import TilePergunta from "./components/tilepergunta";
import { store } from "../../store/userStore.js"
import inMemoryJwt from '../../data/inMemoryJwt';
import ModalAviso from "../../components/aviso";
import ModalEscolha from "../../components/modalEscolha";
import Navbar from "../../components/navbar";
import { url } from "../../Utils";
import Navbar2 from "../../components/navbar2";
function TesteView3(props) {

    //let perguntas =["pergunta 1","pergunta 2"];
    var state = store.getState();

    const [respostaHook, setRespostas] = useState(new Map());
    const [encerrado, setEncerrado] = useState(0);
    const [testes, setTeste] = useState(new Teste3());
    const [mostrarAviso, setMostraAviso] = useState(false);
    const [mostrarEscolha, setMostraEscolha] = useState(false);
    const [modalTitulo, setTituloModal] = useState('');
    const [modalMSG, setModalMSG] = useState('');
    const [logged, setLogged] = useState(true);
    const [respostasCarregadas, setRespostasCarregadas] = useState(true);
    //var testes = new Teste();
    var questao = 0;
    var resposta = 0;  
    const logado2 = useSelector(state => state.user.hora);

    var addResposta = () => {
        testes.updateResposta(questao, resposta, state.user.id2, inMemoryJwt.getToken());
        questao = questao + 1;
        resposta = resposta + 1;
    }

    function refreshLogin(logado) {
        setLogged(logado);
    }

    var encerrarTeste = () => {
        setMostraEscolha(false);
        testes.encerrarTeste(state.user.id2, inMemoryJwt.getToken()).then((resposta) => {
            setEncerrado(parseInt(resposta.success));
            if (parseInt(resposta.success) === 1) {
                setTituloModal('Aviso');
                setModalMSG('Seu teste foi encerrado a partir desse momento você não pode mais modificá-lo');
                setMostraAviso(true);
            } else {
                //console.log(resposta);
                setTituloModal('Aviso');
                setModalMSG('Seu teste NÃO foi encerrado e nem suas respostas foram salvas. ' + resposta.error);
                setMostraAviso(true);
            }
        });
    }
    var mostrarAvisoEncerrar = () => {
        setTituloModal('Aviso');
        setModalMSG('Se você clicar em encerrar você não poderá mais responder ao teste. Deseja mesmo encerrar?');
        setMostraEscolha(true);
    }
    const [perguntas, setPerguntas] = useState([
        
    ]);
    useEffect(() => {
        testes.getRespostaUser(state.user.id2, inMemoryJwt.getToken()).then((_json_resp) => {
            setRespostas(testes.respostas);
            setEncerrado(testes.encerrado);
            setRespostasCarregadas(true);
            if (_json_resp.success != 1) {
                setRespostasCarregadas(false);
            }
        });
        testes.getPerguntasTeste1().then((_json) => {
            setPerguntas(_json);

        });
    }, [logged,logado2]);

    function getResposta(index) {
        if (typeof index === 'undefined') {
            return -1;
        } else {
            return respostaHook.get(index.toString()) == undefined ? -1 : respostaHook.get(index.toString());
        }
    }

    return (
        <>
            <Navbar2 refreshLogin={(logado) => refreshLogin(logado)} />
            <div className="jumbotron pb-5 pt-5">
                    <div className="container">
                        <h1 className="display-3 txt-primary">Teste Final</h1>
                        <p className="txt-primary"></p>
                       
                    </div>
                </div>
            <div className="container2">
                {
                    respostasCarregadas ? <></> :
                        <div className="alert alert-primary alert-dismissible mt-2 text-center" role="alert">
                            Suas respostas não foram carregadas, por favor refaça seu login.
                        </div>
                     
                }
                {
                    perguntas.map((pergunta, index) => {
                        return <TilePergunta teste={testes} pergunta={pergunta.pergunta} respostas={pergunta.respostas} key={index + 500} numero_questao={index} resposta={getResposta(index)} encerrado={parseInt(encerrado) == 1 || !logged} />
                    })
                }
                <button className="btn btn-primary" onClick={mostrarAvisoEncerrar} disabled={parseInt(encerrado) == 1 || !logged}>Encerrar Teste</button>
                <ModalAviso show={mostrarAviso} titulo={modalTitulo} msg={modalMSG} onHide={() => setMostraAviso(false)} />
                <ModalEscolha show={mostrarEscolha} titulo={modalTitulo} msg={modalMSG} onHide={() => setMostraEscolha(false)} sim={encerrarTeste} nao={() => setMostraEscolha(false)} />
            </div>
        </>
    );

}

export default TesteView3;