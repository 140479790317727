import React, { useEffect, useState } from "react";
import ModalAviso from "../../../../components/aviso";
import inMemoryJwt from "../../../../data/inMemoryJwt";
import Teste from "../../../../data/teste";
import {store} from "../../../../store/userStore.js"


function TilePergunta(props) {


    const [respMarcada,setRespMarcada] = useState(1);
    const [msgModal,setMsgModal] = useState('');
    const [tituloModal,setTituloModal] = useState('');
    const [showModal,setShowModal] = useState(false);

    async function salvarResposta(resposta){
        
        setRespMarcada(resposta);
        let resposta_json = await props.teste.updateResposta(props.numero_questao,resposta,state.user.id2,inMemoryJwt.getToken());
       //console.log(resposta_json);
        if (resposta_json.success != 1){
            setMsgModal("Suas respostas não estão sendo salvas.\n"+resposta_json.error);
            setTituloModal('Aviso');
            setShowModal(true);
        }
    }
    
    useEffect(()=>{
        setRespMarcada(props.resposta);
    },[props.resposta]);
    var state = store.getState();
    //console.log("Pergunta" + props.numero_questao);
    return (
        <>
            
            <div className="card mt-2">
                <div className="card-body">
                    <h5 className="card-title" aria-disabled={true}>{props.pergunta}</h5>
                    
                        <div className="form-control">
                            {
                                
                        props.respostas.map((resposta,index) => {
                            //console.log("index" + index + " resposta: "+props.resposta+" marcado:"+(index==respMarcada));
                            //console.log("tipo index: " + (typeof index));
                            //console.log("tipo respMaracada: " + (typeof respMarcada));
                            //console.log("Encerrado: " + props.encerrado);
                            return (
                                    
                                    <div className="form-check" key={index}>
                                        <input className="form-check-input" type="radio" name={"rb"+props.numero_questao} id={resposta.trim()} checked={index==parseInt(respMarcada)} value={index} onChange={(e)=>salvarResposta(e.target.value)} disabled={props.encerrado}/>
                                        <label className="form-check-label" htmlFor="exampleRadios1">
                                            {resposta}
                                        </label>
                                    </div>
                                    
                                
                            )
                        })
                        }
                        </div>
                    
                </div>
            </div>
            <ModalAviso msg={msgModal} titulo={tituloModal} show={showModal} onHide={() => setShowModal(false)} />
        </>
    );
}

export default TilePergunta;