import { useDispatch } from 'react-redux';
import { url} from '../Utils.js'
import inMemoryJWT from './inMemoryJwt';
export default class User{

    logged = 0;
    nome = '';
    email = '';
    tipo = -1;
    datanascimento = 0;
    orientacao_sexual = 0;
    termos = -1;
    receberemail = 0;
    telefone = '';
    senha = '';
    id = 0;
    cpfcnpj = '';
    fotoBase64 = '';
    foto = '';
    id2 = '';
    hora = 0;
    cor = 0;
    categoria_profissional = 0;
    coren = 0;
    setor = 0;
    anos_experiencia = 0;
    trabalha_outra_instituicao = 0;
    professor = 0;
    especializado = 0;
    mestrado = 0;
    doutorado = 0;
    capacitacao_previa = 0;
    experiencia_rcp = 0;
    importancia_rcp = 0;
    dificuldade_aplicacao = 0;
    deficiencia = 0;
   
    deficienciaJson = {
        tem: 0,
        baixavisao: 0,
        visual: 0,
        auditiva: 0,
        fisica: 0,
        transtorno_global: 0,
        outro: "outro",
        n_classificar: 0,
        n_responder: 0
    };
    

    constructor(nome,email,logged){
        this.nome = nome;
        this.email = email;
        this.logged = logged;
    }

    async cadUser(){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                nome: this.nome,
                email: this.email,
                senha:this.senha,
                deficienciaJson : JSON.stringify(this.deficienciaJson),
                tipo:3,
                datanascimento: this.datanascimento,
                cor: this.cor,
                deficiencia: this.deficiencia,
                orientacao_sexual: this.orientacao_sexual,
                categoria_profissional: this.categoria_profissional,
                telefone:this.telefone,
                receberemail: this.receberemail,
                senha: this.senha,
                coren: this.coren,
                setor: this.setor,
                anos_experiencia: this.anos_experiencia,
                trabalha_outra_instituicao: this.trabalha_outra_instituicao,
                professor: this.professor,
                especializado: this.especializado,
                mestrado: this.mestrado,
                doutorado: this.doutorado,
                capacitacao_previa: this.capacitacao_previa,
                experiencia_rcp: this.experiencia_rcp,
                importancia_rcp: this.importancia_rcp,
                dificuldade_aplicacao: this.dificuldade_aplicacao
            })
        };
        const response = await fetch(url.concat('/User/cadUsers2.php'),requestOptions);
        //console.log(response.body);
        const jsonresp = await response.json();
        
        return jsonresp;
    }

    async cadUserAdmin(idUser,jwt,tipo){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                nome: 'nome',
                email: this.email,
                senha:this.senha,                
                tipo: tipo,
                senha: this.senha,               
                idUser:idUser,
                jwt:jwt
            })
        };
        const response = await fetch(url.concat('/User/cadUsersAdmin.php'),requestOptions);
        const jsonresp = await response.json();
        
        return jsonresp;
    }

    async recuperarSenha(email){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: email
            })
        };
        const response = await fetch(url.concat('/User/recuperarsenha.php'),requestOptions);
        const jsonresp = await response.json();
        
        return jsonresp.success;
    }


    async login(email,senha){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({email: email, senha: senha })
        };
        const response = await fetch('/Anjus/User/login.php',requestOptions);
        const jsonresp = await response.json();
        this.logged = jsonresp.id > 0;
        if (this.logged){
            this.id = jsonresp.id;
            this.nome = jsonresp.nome;
            this.email = jsonresp.email;
            this.profissao = jsonresp.profissao;
            this.receberemail = jsonresp.receberemail;
            this.sexo = jsonresp.sexo;
            this.telefone = jsonresp.telefone;
            this.termos = jsonresp.termos;
            this.tipo = jsonresp.tipo;
            this.cpfcnpj = jsonresp.cpfcnpj;
            this.foto = jsonresp.foto;
        }

    }

    async loginJWT(email,senha){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({email: email, senha: senha })
        };
        
        const response = await fetch(url.concat('/User/loginJWT.php'),requestOptions);
        const jsonresp = await response.json();
        this.logged = jsonresp.id != -1;
        
        if (this.logged){
            this.id2 = jsonresp.id;
            this.id = 1;
            this.nome = jsonresp.nome;
            this.email = jsonresp.email;
            this.profissao = jsonresp.profissao;
            this.receberemail = jsonresp.receberemail;
            this.sexo = jsonresp.sexo;
            this.telefone = jsonresp.telefone;
            this.termos = jsonresp.termos;
            this.tipo = jsonresp.tipo;
            this.cpfcnpj = jsonresp.cpfcnpj;
            this.foto = jsonresp.foto;
            this.hora = Date.now();
            inMemoryJWT.setRefreshTokenEndpoint(url.concat('/utils/refresh_token.php'));
            inMemoryJWT.setToken(jsonresp.jwt,jsonresp.tokenExpiry);
            inMemoryJWT.setTipo_user(jsonresp.tipo);
        }

    }
    logout() {
        this.id = 0;
        this.id2 = "";
        this.nome = "anonimo";
        this.logged = false;
    }
    async trocarSenha(senhaAntiga,novaSenha){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({idUser: this.id, novaSenha: novaSenha,senha:senhaAntiga })
        };
        const response = await fetch(url.concat('/User/trocarSenha.php'),requestOptions);
        const jsonresp = await response.json();
        return jsonresp.success;
    }


    

    async getByCpf(cpfcnpj){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({cpfcnpj: cpfcnpj})
        };
        const response = await fetch('/Anjus/User/getByCpf.php',requestOptions);
        const jsonresp = await response.json();
        this.nome = jsonresp.nome;
        this.id2 = jsonresp.idUser;
        
        this.foto = jsonresp.foto;
        return jsonresp;
    }
    async getByEmail(email){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({email: email})
        };
        const response = await fetch('/User/getByEmail.php',requestOptions);
        const jsonresp = await response.json();
        this.nome = jsonresp.nome;
        this.id2 = jsonresp.idUser;
        
        return jsonresp;
    }
    async getTipoUser(id){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({id: id})
        };
        const response = await fetch(url+'/User/getTipoUser.php',requestOptions);
        const jsonresp = await response.json();
        //const jsonresp = JSON.parse('{"tipo":1}');
        
        return jsonresp.tipo;
    }
    async getUser(){
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({ title: this.nome })
        };
        const response = await fetch(url.concat('/User/teste.php'),requestOptions);
        const jsonresp = await response.json();
        

    }

    async updateUser(){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({idUser: this.id})
        };
        const response = await fetch('/Anjus/User/updateUser.php',requestOptions);
        const jsonresp = await response.json();
        this.logged = jsonresp.id > 0;
        if (this.logged){
            this.id = jsonresp.id;
            this.nome = jsonresp.nome;
            this.email = jsonresp.email;
            this.profissao = jsonresp.profissao;
            this.receberemail = jsonresp.receberemail;
            this.sexo = jsonresp.sexo;
            this.telefone = jsonresp.telefone;
            this.termos = jsonresp.termos;
            this.tipo = jsonresp.tipo;
            this.cpfcnpj = jsonresp.cpfcnpj
        }
    }

    async getClientes(idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                idUser: idUser,
                jwt:jwt
            })
        };
        const response = await fetch('/Anjus/User/getClientes.php',requestOptions);
        const jsonresp = await response.json();
        return jsonresp;
    }

    async getClientesCPF(cpf,idUser,jwt){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                cpf:cpf,
                idUser: idUser,
                jwt:jwt
            })
        };
        const response = await fetch('/Anjus/User/getClientesCPF.php',requestOptions);
        const jsonresp = await response.json();
        return jsonresp;
    }
    

    print(){
        console.log(this.nome);
    }

    

}