import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DownloadTest1 from "../../components/downloadTest1";
import DownloadTest2 from "../../components/downloadTeste2";
import DownloadTest3 from "../../components/downloadTeste3";
import DownloadTestPratico from "../../components/downloadTestePratico";
import DownloadTestPratico2 from "../../components/downloadTestePratico2";
import DownloadTestPratico3 from "../../components/downloadTestePratico3";
import Navbar from "../../components/navbar";
import Navbar2 from "../../components/navbar2";
import Configs from "../../data/configs";
import inMemoryJwt from "../../data/inMemoryJwt";
import User from "../../data/user";
import { store } from "../../store/userStore";

function AdminPage() {


    const [modalShow, setModalShow] = useState(false);
    const [loginModal, setLoginModal] = useState(false);

    const [teste1Liberado, setTeste1Liberado] = useState(0);
    const [teste2Liberado, setTeste2Liberado] = useState(0);
    const [teste3Liberado, setTeste3Liberado] = useState(0);
    const [treinamentoLiberado, setTreinamentoLiberado] = useState(0);
    const [configs, setConfigs] = useState(new Configs());
    const [resulstadosTeste1, setResultadosTeste1] = useState('');
    const [resulstadosTeste2, setResultadosTeste2] = useState('');
    const [resulstadosTeste3, setResultadosTeste3] = useState('');
    const [resulstadosTestePratico, setResultadosTestePratico] = useState('');

    let navigate = useNavigate();
    var state = store.getState();
    const dispatch = useDispatch();
    const [logged, setLogged] = useState(state.user.logged);
    const [gerandoCSVTeste1, setGerandoCSVTeste1] = useState(false);
    const [csvTeste1Gerado,setCsvTeste1Gerado] = useState(false);
    const [gerandoCSVTeste2, setGerandoCSVTeste2] = useState(false);
    const [csvTeste2Gerado,setCsvTeste2Gerado] = useState(false);

    const [gerandoCSVTeste3, setGerandoCSVTeste3] = useState(false);
    const [csvTeste3Gerado,setCsvTeste3Gerado] = useState(false);

    const [gerandoCSVTestePratico, setGerandoCSVTestePratico] = useState(false);
    const [csvTestePraticoGerado,setCsvTestePraticoGerado] = useState(false);

    const [gerandoCSVTestePratico2, setGerandoCSVTestePratico2] = useState(false);
    const [gerandoCSVTestePratico3, setGerandoCSVTestePratico3] = useState(false);
    const [csvTestePraticoGerado2,setCsvTestePraticoGerado2] = useState(false);
    const [csvTestePraticoGerado3,setCsvTestePraticoGerado3] = useState(false);
    const [resulstadosTestePratico2, setResultadosTestePratico2] = useState('');
    const [resulstadosTestePratico3, setResultadosTestePratico3] = useState('');

    function refreshLogin(logado) {
        setLogged(logado);
    }
    
    useEffect(() => {
        var user = new User();
       user.getTipoUser(state.user.id2).then((tipo)=>{
            if (tipo != 1){
                navigate("/");
            }
        });
        
        Configs.getConfigs().then((retorno) => {
            setTeste1Liberado(retorno.teste1Liberado);
            setTeste2Liberado(retorno.teste2Liberado);
            setTeste3Liberado(retorno.teste3Liberado);
            setTreinamentoLiberado(retorno.treinamentoLiberado);
        });
    }, []);

    function habilitarTeste1() {
        Configs.habilitarTeste1(state.user.id2, inMemoryJwt.getToken()).then(() => {
            Configs.getConfigs().then((retorno) => {
                setTeste1Liberado(retorno.teste1Liberado);
            });
        });
    }

    function desabilitarTeste1() {
        Configs.desabilitarTeste1(state.user.id2, inMemoryJwt.getToken()).then(() => {
            Configs.getConfigs().then((retorno) => {
                setTeste1Liberado(retorno.teste1Liberado);
            });
        });
    }

    function habilitarTeste2() {
        Configs.habilitarTeste2(state.user.id2, inMemoryJwt.getToken()).then(() => {
            Configs.getConfigs().then((retorno) => {
                setTeste2Liberado(retorno.teste2Liberado);
            });
        });
    }

    function desabilitarTeste2() {
        Configs.desabilitarTeste2(state.user.id2, inMemoryJwt.getToken()).then(() => {
            Configs.getConfigs().then((retorno) => {
                setTeste2Liberado(retorno.teste2Liberado);
            });
        });
    }

    function habilitarTeste3() {
        Configs.habilitarTeste3(state.user.id2, inMemoryJwt.getToken()).then(() => {
            Configs.getConfigs().then((retorno) => {
                setTeste3Liberado(retorno.teste3Liberado);
            });
        });
    }

    function desabilitarTeste3() {
        Configs.desabilitarTeste3(state.user.id2, inMemoryJwt.getToken()).then(() => {
            Configs.getConfigs().then((retorno) => {
                setTeste3Liberado(retorno.teste3Liberado);
            });
        });
    }

    function habilitarTreinamento() {
        Configs.habilitarTreinamento(state.user.id2, inMemoryJwt.getToken()).then(() => {
            Configs.getConfigs().then((retorno) => {
                setTreinamentoLiberado(retorno.treinamentoLiberado);
            });
        });
    }

    function desabilitarTreinamento() {
        Configs.desabilitarTreinamento(state.user.id2, inMemoryJwt.getToken()).then(() => {
            Configs.getConfigs().then((retorno) => {
                setTreinamentoLiberado(retorno.treinamentoLiberado);
            });
        });
    }

    function exportarTeste1() {
        setGerandoCSVTeste1(true);
        Configs.exportarTeste1(state.user.id2, inMemoryJwt.getToken()).then((resposta) => {
            setResultadosTeste1(resposta);
            setCsvTeste1Gerado(true);
        });
        setGerandoCSVTeste1(false);
    }

    function exportarTeste2() {
        setGerandoCSVTeste2(true);
        Configs.exportarTeste2(state.user.id2, inMemoryJwt.getToken()).then((resposta) => {
            setResultadosTeste2(resposta);
            setCsvTeste2Gerado(true);
        });
        setGerandoCSVTeste2(false);
    }

    function exportarTeste3() {
        setGerandoCSVTeste3(true);
        Configs.exportarTeste3(state.user.id2, inMemoryJwt.getToken()).then((resposta) => {
            setResultadosTeste3(resposta);
            setCsvTeste3Gerado(true);
        });
        setGerandoCSVTeste3(false);
    }

    function exportarTestePratico() {
        setGerandoCSVTestePratico(true);
        Configs.exportarTestePratico(state.user.id2, inMemoryJwt.getToken()).then((resposta) => {
            setResultadosTestePratico(resposta);
            setCsvTestePraticoGerado(true);
        });
        setGerandoCSVTestePratico(false);
    }

    function exportarTestePratico2() {
        setGerandoCSVTestePratico2(true);
        Configs.exportarTestePratico2(state.user.id2, inMemoryJwt.getToken()).then((resposta) => {
            setResultadosTestePratico2(resposta);
            setCsvTestePraticoGerado2(true);
        });
        setGerandoCSVTestePratico2(false);
    }

    function exportarTestePratico3() {
        setGerandoCSVTestePratico3(true);
        Configs.exportarTestePratico3(state.user.id2, inMemoryJwt.getToken()).then((resposta) => {
            setResultadosTestePratico3(resposta);
            setCsvTestePraticoGerado3(true);
        });
        setGerandoCSVTestePratico3(false);
    }

    return (
        <>
            <Navbar2 refreshLogin={() => refreshLogin()} />
            <div className=" container2 p-2">
                <div className="row">
                    <div className="col">
                        <div className="card">
                            <h5 className="card-header">Teste Inicial(1)-{teste1Liberado ? "Habilitado" : "Desabilitado"}</h5>
                            <div className="card-body">
                                <h5 className="card-title">Habilitar/Desabilitar</h5>
                                <p className="card-text">Aqui você pode habilitar ou desabilitar o primeiro teste clicando no botão abaixo.</p>
                                {
                                    teste1Liberado ?
                                        <a className="btn btn-primary" onClick={() => desabilitarTeste1()}>Desabilitar</a>
                                        :
                                        <a className="btn btn-primary" onClick={() => habilitarTeste1()}>Habilitar</a>
                                }

                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card">
                            <h5 className="card-header">Teste Imediato-{teste3Liberado ? "Habilitado" : "Desabilitado"}</h5>
                            <div className="card-body">
                                <h5 className="card-title">Habilitar/Desabilitar</h5>
                                <p className="card-text">Aqui você pode habilitar ou desabilitar o último teste clicando no botão abaixo.</p>
                                {
                                    teste3Liberado ?
                                        <a className="btn btn-primary" onClick={() => desabilitarTeste3()}>Desabilitar</a>
                                        :
                                        <a className="btn btn-primary" onClick={() => habilitarTeste3()}>Habilitar</a>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <h5 className="card-header">Pós Teste(2)-{teste2Liberado ? "Habilitado" : "Desabilitado"}</h5>
                            <div className="card-body">
                                <h5 className="card-title">Habilitar/Desabilitar</h5>
                                <p className="card-text">Aqui você pode habilitar ou desabilitar o último teste clicando no botão abaixo.</p>
                                {
                                    teste2Liberado ?
                                        <a className="btn btn-primary" onClick={() => desabilitarTeste2()}>Desabilitar</a>
                                        :
                                        <a className="btn btn-primary" onClick={() => habilitarTeste2()}>Habilitar</a>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-4">
                        <div className="card">
                            <h5 className="card-header">Exportar Teste Inicial(1)</h5>
                            <div className="card-body">
                                <h5 className="card-title">Exportar</h5>
                                <p className="card-text">Aqui você pode exportar para csv os resultados do primeiro teste.</p>
                                {
                                    gerandoCSVTeste1 ?
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        :
                                        <a className="btn btn-primary" onClick={() => exportarTeste1()}>Exportar</a>
                                }
                                {
                                    csvTeste1Gerado ?
                                    <div className="row text-center">
                                        <DownloadTest1 blob={resulstadosTeste1} />
                                    </div>
                                    
                                    :
                                    <></>
                                }
                                
                            </div>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="card">
                            <h5 className="card-header">Exportar Teste Imediato</h5>
                            <div className="card-body">
                                <h5 className="card-title">Exportar</h5>
                                <p className="card-text">Aqui você pode exportar para csv os resultados do teste imediato.</p>
                                {
                                    gerandoCSVTeste3 ?
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        :
                                        <a className="btn btn-primary" onClick={() => exportarTeste3()}>Exportar</a>
                                }
                                {
                                    csvTeste3Gerado ?
                                    <div className="row text-center">
                                        <DownloadTest3 blob={resulstadosTeste3} />
                                    </div>
                                    
                                    :
                                    <></>
                                }
                                
                            </div>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="card">
                            <h5 className="card-header">Exportar Pós Teste(2)</h5>
                            <div className="card-body">
                                <h5 className="card-title">Exportar</h5>
                                <p className="card-text">Aqui você pode exportar para csv os resultados do último teste.</p>
                                {
                                    gerandoCSVTeste2 ?
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        :
                                        <a className="btn btn-primary" onClick={() => exportarTeste2()}>Exportar</a>
                                }
                                {
                                    csvTeste2Gerado ?
                                    <div className="row text-center">
                                        <DownloadTest2 blob={resulstadosTeste2} />
                                    </div>
                                    
                                    :
                                    <></>
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                <div className="col-4">
                        <div className="card">
                            <h5 className="card-header">Treinamento(1)-{treinamentoLiberado ? "Habilitado" : "Desabilitado"}</h5>
                            <div className="card-body">
                                <h5 className="card-title">Habilitar/Desabilitar</h5>
                                <p className="card-text">Aqui você pode habilitar ou desabilitar o treinamento clicando no botão abaixo.</p>
                                {
                                    treinamentoLiberado ?
                                        <a className="btn btn-primary" onClick={() => desabilitarTreinamento()}>Desabilitar</a>
                                        :
                                        <a className="btn btn-primary" onClick={() => habilitarTreinamento()}>Habilitar</a>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        >
                    </div>
                    <div className="col-4">
                        <div className="card">
                            <h5 className="card-header">Cadastrar Administrador/Pesquisador</h5>
                            <div className="card-body">
                                <h5 className="card-title">Cadastro</h5>
                                <p className="card-text">Aqui você pode cadastrar Administrador ou Pesquisador.</p>
                                

                                
                                <Link className='nolink' to="/cadadmin"><button className="btn btn-primary ms-sm-1">Cadastrar</button></Link>
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                
                    <div className="col-4">
                        <div className="card">
                            <h5 className="card-header">Exportar Teste Prático</h5>
                            <div className="card-body">
                                <h5 className="card-title">Exportar</h5>
                                <p className="card-text">Aqui você pode exportar para csv os resultados do teste Prático.</p>
                                {
                                    gerandoCSVTestePratico ?
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        :
                                        <a className="btn btn-primary" onClick={() => exportarTestePratico()}>Exportar</a>
                                }
                                {
                                    csvTestePraticoGerado ?
                                    <div className="row text-center">
                                        <DownloadTestPratico blob={resulstadosTestePratico} />
                                    </div>
                                    
                                    :
                                    <></>
                                }
                                
                            </div>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="card">
                            <h5 className="card-header">Exportar Teste Prático Imediato</h5>
                            <div className="card-body">
                                <h5 className="card-title">Exportar</h5>
                                <p className="card-text">Aqui você pode exportar para csv os resultados do teste Prático imediato.</p>
                                {
                                    gerandoCSVTestePratico2 ?
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        :
                                        <a className="btn btn-primary" onClick={() => exportarTestePratico2()}>Exportar</a>
                                }
                                {
                                    csvTestePraticoGerado2 ?
                                    <div className="row text-center">
                                        <DownloadTestPratico2 blob={resulstadosTestePratico2} />
                                    </div>
                                    
                                    :
                                    <></>
                                }
                                
                            </div>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="card">
                            <h5 className="card-header">Exportar Teste Prático Final</h5>
                            <div className="card-body">
                                <h5 className="card-title">Exportar</h5>
                                <p className="card-text">Aqui você pode exportar para csv os resultados do teste Prático Final.</p>
                                {
                                    gerandoCSVTestePratico3 ?
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        :
                                        <a className="btn btn-primary" onClick={() => exportarTestePratico3()}>Exportar</a>
                                }
                                {
                                    csvTestePraticoGerado3 ?
                                    <div className="row text-center">
                                        <DownloadTestPratico3 blob={resulstadosTestePratico3} />
                                    </div>
                                    
                                    :
                                    <></>
                                }
                                
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    );
}
export default AdminPage;