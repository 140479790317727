function Combocor(props) {
    return (
        <>
        <label htmlFor={"cmb"+props.label.trim()} className="txt-roxo">{props.label}</label>
        <select className="form-select" aria-label="Default select example" id={"cmb"+props.label.trim()}  value={props.value} onChange={(e) => props.setValue(e.target.value)}>
            <option value="0">Amarelo</option>
            <option value="1">Branco</option>
            <option value="2">Indígena</option>
            <option value="3">Pardo</option>
            <option value="4">Preto</option>
            <option value="5">Outro</option>
            <option value="6">Prefiro não me classificar</option>
            <option value="7">Prefiro não responder</option>
        </select>
        </>
    );
}

export default Combocor;