import { useEffect, useState } from "react";
import Navbar2 from "../../components/navbar2";
import img_cpr from "../../image/cpr.png";
import { store } from "../../store/userStore";
import eduesley from "../../image/eduesley.png";
import roberta from "../../image/roberta.png";
import debora from "../../image/debora.png";
import CardPessoas from "./components/cardpessoas";

function QuemSomosScreen() {
    var state = store.getState();
    const [logged, setLogged] = useState(state.user.logged);

    useEffect(() => {
        //inMemoryJwt.getTipo_user(state.user.id2).then(tipo => setTipoUser(tipo));
        //refreshToken(5);

        setLogged(state.user.logged);
    }, [logged]);

    function refreshLogin(logado) {
        setLogged(logado);
    }

    return (

        <>
            <Navbar2 refreshLogin={() => refreshLogin()} />

            <main>

                <section>
                    <div className="jumbotron pb-5 pt-5">
                        <div className="container">
                            <h1 className="display-3 txt-primary">Quem Somos!</h1>
                            <p className="txt-primary">A pesquisa busca contribuir com o hospital através da educação continuada ofertada aos profissionais da enfermagem aumentando o conhecimento acerca do atendimento em parada cardiorrespiratória, de forma a promover uma melhoria na assistência de enfermagem.</p>

                        </div>
                        <div className="row mt-3 ps-2 pe-2">
                            <div className="col-xl-2" />
                            <div className="col-xl-8">
                                <CardPessoas
                                    foto={eduesley}
                                    nome={"Eduesley Santana Santos"}
                                    ocupacao={"Professor Adjunto do Departamento de Enfermagem da Universidade Federal de Sergipe. Coordenador do Programa de Pós-Graduação em Enfermagem – PPGEN"}
                                    listagraduacao={
                                        [
                                            "Graduação em Enfermagem Bacharelado pela Universidade Federal de Sergipe.",
                                            "Aprimoramento em Enfermagem em Cardiologia pelo Instituto do Coração (InCor) do Hospital das Clínicas da Faculdade de Medicina da Universidade de São Paulo.",
                                            "Doutor em Ciências da Saúde (Cardiologia) pelo Instituto do Coração (InCor) do Hospital das Clínicas da Faculdade de Medicina da Universidade de São Paulo.",
                                            "Pós-Doutorado pela Faculdade de Medicina da Universidade de São Paulo.",
                                            "Membro do Grupo Interdisciplinar em Estudos em Cuidados Críticos (GIESCC)."
                                        ]
                                    }
                                />
                            </div>
                            <div className="col-xl-2" />
                        </div>
                        <div className="row mt-3 ps-2 pe-2">
                            <div className="col-xl-2" />
                            <div className="col-xl-8">
                                <CardPessoas
                                    foto={roberta}
                                    nome={"Renata  "}
                                    ocupacao={"Mestranda em ciências aplicadas à saúde Universidade Federal de Sergipe (UFS)"}
                                    listagraduacao={
                                        [
                                            "Graduação em enfermagem Bacharelado pela Faculdade de Aracaju (FACAR).",
                                            "Especialização em urgência e emergência pela Faculdade Jardins (FACJARDINS).",
                                            "Especialização em Docência em enfermagem pela FACULDADE VENDA NOVA DO IMIGRANTE (FAVENI).",
                                            "Especialização em saúde pública com ênfase na estratégia de saúde da família pela FACULDADE VENDA NOVA DO IMIGRANTE (FAVENI).",
                                            "Membro do Grupo Interdisciplinar em Estudos em Cuidados Críticos (GIESCC).",
                                            "Membro do Grupo de Estudos Plenamente- Abordagens em Saúde Mental (GEPASM)."
                                        ]
                                    }
                                />
                            </div>
                        </div>
                        <div className="row mt-3 ps-2 pe-2">
                            <div className="col-xl-2" />
                            <div className="col-xl-8">
                                <CardPessoas
                                    foto={debora}
                                    nome={"Débora Costa Gomes Coelho"}
                                    ocupacao={"Mestranda em Ciências Aplicadas à Saúde- Programa de Pós-Graduação (UFS)."}
                                    listagraduacao={[
                                        "Graduada em Enfermagem – Universidade Federal de Sergipe (UFS).",
                                        "Especialista em Terapia Intensiva Adulto – Residência Integrada Multiprofissional (FBHC).",
                                        "Pós-graduada em Gestão dos Serviços de Enfermagem – Pós-graduação (FPN).",
                                        "Membro do Grupo Interdisciplinar em Estudos em Cuidados Críticos (GIESCC).",
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </section>


                <hr></hr>
            </main>
        </>
    );
}

export default QuemSomosScreen;