function CampoSwitch(props) {
    return (
        <>
            <div className="form-check form-switch">
                <label className="form-check-label" htmlFor={"check"+props.label.trim()}>{props.label}</label>
                <input className="form-check-input" type="checkbox" role="switch" id={"check"+props.label.trim()} onChange={(e) => props.setValue(e.target.checked)} />
            </div>
        </>
    );
}

export default CampoSwitch;