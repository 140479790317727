import './modalEscolha.css'
import React,{useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


function ModalEscolha(props) {

    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.titulo}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>{props.msg}</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={props.sim}>Sim</Button>
                    <Button variant="secondary" onClick={props.nao}>Não</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalEscolha;