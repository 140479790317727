import { useEffect, useState } from "react";
import Navbar2 from "../../components/navbar2";
import img_cpr from "../../image/cpr.png";
import { store } from "../../store/userStore";
import cep from "../../image/cep.png";
import cirurgia from "../../image/cirurgia.png";
import primavera from "../../image/primavera.png";
function Apresentacao() {

    var state = store.getState();
    const [logged, setLogged] = useState(state.user.logged);
    
    useEffect(() => {
        //inMemoryJwt.getTipo_user(state.user.id2).then(tipo => setTipoUser(tipo));
        //refreshToken(5);

        setLogged(state.user.logged);
    }, [logged]);

    function refreshLogin(logado) {
        setLogged(logado);
    }

    return (
        <>

            
            <Navbar2 refreshLogin={() => refreshLogin()} />
          
            <main>
            
                <div className="jumbotron pb-5 pt-5">
                    <div className="container">
                        <h1 className="display-3 txt-primary">Programa de treinamento em emergências cardiovasculares para equipe de enfermagem</h1>
                        <p className="txt-primary">Sejam bem-vindos ao treinamento de suporte básico de vida em adultos, proveniente de 

um projeto de pesquisa intitulado Avaliação do impacto de um programa de treinamento em emergências cardiovasculares para a equipe de enfermagem: um estudo quase-experimental. </p>
                       
                    </div>
                </div>


                <div className="container mt-5">
                    <h3>Instituições Participantes</h3>
                    <div className="row">
                        
                        
                        <div className="col-lg-4 mt-2">
                            <img src={primavera}/>
                        </div>
                        
                        <div className="col-lg-4 mt-2">
                            <img src={cirurgia}/>
                        </div>
                        <div className="col-lg-4 mt-2">
                            <img src={cep}/>
                        </div>
                        
                    </div>
                </div>
                <hr></hr>
            </main>
        </>
    );
}

export default Apresentacao;