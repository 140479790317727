import { useEffect, useState } from "react";
import Navbar2 from "../../components/navbar2";
import img_cpr from "../../image/cpr.png";
import { store } from "../../store/userStore";
import smartphone from "../../image/smartphone.png";
import email from "../../image/email.png";
import "./detalhes.css";
function ApresentacaoDetalhesScreen(){
    var state = store.getState();
    const [logged, setLogged] = useState(state.user.logged);

    useEffect(() => {
        //inMemoryJwt.getTipo_user(state.user.id2).then(tipo => setTipoUser(tipo));
        //refreshToken(5);

        setLogged(state.user.logged);
    }, [logged]);

    function refreshLogin(logado) {
        setLogged(logado);
    }
    
    return(
    
        <>
        <Navbar2 refreshLogin={() => refreshLogin()} />
          
          <main>
          
          
              <div className="jumbotron pb-5 pt-5">
                  <div className="container">
                      <h1 className="display-3 txt-primary">Orientação aos Voluntários</h1>
                      <p className="txt-primary">O projeto foi submetido à Plataforma Brasil e ao Comitê de Ética e Pesquisa da Universidade Federal de Sergipe (CEP-UFS). Serão adotados os preceitos estabelecidos pela Resolução no 466 de 12 de dezembro de 2012, do Conselho Nacional de Saúde, que estabelece as diretrizes e normas regulamentadoras de pesquisas envolvendo seres humanos.</p>
                      <p>A resolução n° 466/2012, traz em seus termos e definições em relação ao risco sobre pesquisa que envolve seres humanos sejam eles físicos, sociais ou intelectuais. O item que trata sobre aspectos éticos da pesquisa envolvendo seres humanos afirma que toda pesquisa deve ter aspecto ético, respeito ao participante, com dignidade, conter termo de consentimento livre e esclarecido. Dos riscos e benefícios, todas as pesquisas com seres humanos envolvem riscos, onde o pesquisador deve prezar pelos benefícios maiores que os riscos na pesquisa, buscando ao máximo evitar danos ao voluntários.</p>
                      <p>Espera-se que esse projeto  ofereça ganhos à população ao reduzir agravos por emergências cardiovasculares nos hospitais de Sergipe onde for implementado, como também benefícios aos profissionais treinados, assim contribuindo para a melhoria da qualidade da assistência de enfermagem.</p>
                      <p>A pesquisa poderá trazer riscos eventuais no desenvolvimento do treinamento teórico e/ou prático sendo físico ou intelectual, onde os pesquisadores envolvidos irão se responsabilizar para que não haja danos aos voluntários dessa pesquisa. Os pesquisadores também assegurarão aos voluntários da pesquisa as condições de acompanhamento, tratamento ou de orientação, conforme necessário.</p>
                      <p>A hipótese deste trabalho assume que os profissionais de enfermagem apresentam dificuldades de como proceder ao atendimento de emergências cardiovasculares, logo a oferta de treinamento qualificado melhora a assistência e reduz a mortalidade de pacientes vítimas de emergências cardiovasculares.</p>
                      <a href='https://rcp.hbrsoftware.com.br/rcp-termo.pdf' target="_blank" id="semlink"><i class="fa-solid fa-download"/>Faça o download do Termo de Consentimento</a>
                      <h5 className="mt-5">Em caso de dúvida favor entrar em contato através do telefone ou e-mail abaixo.</h5>
                      <span  className="align-bottom" id="semlink"><a href="https://wa.me/5579998692399" id="semlink"><h6><img src={smartphone} width="27" height="27" className="me-1"/>(79)99869-2399</h6></a></span>
                      <span  className="align-bottom"><a href="mailto:renata.roberta.dantas@gmail.com" id="semlink"><h6><img src={email} width="27" height="27" className="me-1"/>renata.roberta.dantas@gmail.com</h6></a></span>
                     
                  </div>
              </div>
              
              <hr></hr>
          </main>
        </>
    );
}

export default ApresentacaoDetalhesScreen;