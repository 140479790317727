import { useState } from "react";
import User from "../../../data/user";
import CampoSwitch from "./camposwitch";
import CampoSwitchDeficiencia from "./camposwitch_deficiencia";

function Combodeficiencia(props) {


    const [simnao,setSimNao] = useState(0);
    const [deficienciaJson, setDeficienciaJson] = useState(props.deficienciaJson);
    const [outro,setOutro] = useState(0);
    const [txtOutro,setTextoOutro] = useState("");
    function setChangeSimNao(value){
        setSimNao(value);
        deficienciaJson.tem = value;
        console.log(value);
    }

    function setDeficiencia(campo,value){
        deficienciaJson[campo] = value;
        props.setDeficienciaJson(deficienciaJson);
    }

    function setOutros(value){
        setOutro(value);
    }

    function setTxtOutros(value){
        setTextoOutro(value);
        deficienciaJson.outro = value;
        props.setDeficienciaJson(deficienciaJson);
    }
    
    return (
        <>
        <label htmlFor={"cmb"+props.label.trim()} className="txt-roxo">{props.label}</label>
        <select className="form-select" aria-label="Default select example" id={"cmb"+props.label.trim()}  value={props.value} onChange={(e) => setChangeSimNao(e.target.value)}>
            <option value="0">Não</option>
            <option value="1">Sim</option>            
        </select>
        {
            simnao == 1 ?
                    <>
                        <div className="row">
                            <div className="col-sm-12">

                                <CampoSwitchDeficiencia label="Baixa visão ou visão subnormal" campo={"baixavisao"} setValue={setDeficiencia} />
                                <CampoSwitchDeficiencia label="Deficiência Visual" campo={"visual"} setValue={setDeficiencia} />
                                <CampoSwitchDeficiencia label="Deficiência auditiva" campo={"auditiva"} setValue={setDeficiencia} />
                                <CampoSwitchDeficiencia label="Física" campo={"fisica"} setValue={setDeficiencia} />
                                <CampoSwitchDeficiencia label="Transtorno global do desenvolvimento" campo={"transtorno_global"} setValue={setDeficiencia} />
                                <CampoSwitchDeficiencia label="Prefiro não me classificar" campo={"n_classificar"} setValue={setDeficiencia} />
                                <CampoSwitchDeficiencia label="Prefiro não responder" campo={"n_responder"} setValue={setDeficiencia} />

                                <div className="input-group mb-3">
                                    
                                    <span className="align-middle" id="inputGroup-sizing-default">
                                    <div className="form-check form-switch me-2 align-middle">
                                        <label className="form-check-label" htmlFor={"checkOutros"}>Outros</label>
                                        <input className="form-check-input" type="checkbox" role="switch" id={"checkOutros"} onChange={(e) => setOutros(e.target.checked)} />
                                    </div> 
                                    </span>
                                    {
                                        outro ?
                                        <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" onChange={(e) => setTxtOutros( e.target.value)} value={txtOutro} />
                                        :
                                        <></>
                                    }
                                    
                                </div>

                            </div>
                        </div>
                    </>
            :
            <></>
        }
        </>
    );
}

export default Combodeficiencia;