import "./cardpessoas.css";
function CardPessoas(props){


    return(
        <>
            <div className="card" id="card-pessoas">
                <div className="row p-2">
                    <div className="col-md-4 text-center">
                        <img src={props.foto}/>
                    </div>
                    <div className="col-md-8">
                        <h4>{props.nome}</h4>
                        <h6>{props.ocupacao}</h6>
                        {
                            props.listagraduacao.map((graduacao,index)=><p key={"gr"+index}>{graduacao}</p>)
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardPessoas;