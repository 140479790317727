function Comboinstrucao(props) {
    return (
        <>
        <label htmlFor={"cmb"+props.label.trim()} className="txt-roxo">{props.label}</label>
        <select className="form-select" aria-label="Default select example" id={"cmb"+props.label.trim()}  value={props.value} onChange={(e) => props.setValue(e.target.value)}>
            <option value="0">Nível Técnico</option>
            <option value="1">Graduação</option>
            <option value="2">Especialização</option>
            <option value="3">Residência</option>
            <option value="4">Mestrado</option>
            <option value="5">Doutorado</option>
            <option value="5">Pós-Doutorado</option>
        </select>
        </>
    );
}

export default Comboinstrucao;