import "./login.css";
import React, { useState } from "react";
import User from "../../data/user";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

function LoginScreen(props) {
  const [email, setEmail] = useState();
  const [senha, setSenha] = useState();
  const [falhaLogin,setFalhaLogin] = useState(false);

  const dispatch = useDispatch();

  async function login() {
    let user = new User();
    
    await user.loginJWT(email, senha);
    if (user.logged){
      dispatch({
        type: "SET",
        user: user
      });
      props.setLogged();
      props.fecharModal();
      setFalhaLogin(false);
    } else {
      setFalhaLogin(true);
    }
  }

  return (
    <div className="mx-auto">
      <form className="form-login mx-auto">
        <h3 className="d-flex flex-row justify-content-center">Entrar</h3>
        <div className="form-group input-icons">
          <i className="fas fa-envelope icon"></i>
          <input
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            className="form-control input-field mx-auto"
            placeholder="Digite o email"
          />
        </div>
        <div className="form-group input-icons">
          <i className="fas fa-key icon"></i>
          <input
            type="password"
            onChange={(e) => setSenha(e.target.value)}
            className="form-control input-field"
            placeholder="Digite a senha"
          />
        </div>
        <button
          type="button"
          className="btn btn-lg btn-block btn-login"
          onClick={login}
        >
          Entrar
        </button>
        <div className="d-flex justify-content-between">
          <button type="button" className="btn btn-link">
          <Link to="/caduser">Cadastrar</Link>
          </button>
          <button type="button" className="btn btn-link">
            <Link to="/recuperarsenha">Esqueci</Link>
          </button>
        </div>
        {
          falhaLogin ?
          <div className="txtfalha d-flex justify-content-center">
            Usuário ou senha inválidos
        </div>
        :<></>
        }
      </form>
    </div>
  );
}

export default LoginScreen;