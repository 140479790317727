//export const url = 'http://hbrsoftware.com.br/rcp'

export const url = '.'; //local e server
//export const urlImgs = '/rcp'; //local
export const urlImgs = ''; //server
//export const urlImgs = '.';


export function toData(epoca){
    var data = new Date(0);
    data.setUTCSeconds(epoca);
    return (data.toLocaleDateString() + " às " + data.toLocaleTimeString());
}

export function toDataShort(epoca){
    var data = new Date(0);
    data.setUTCSeconds(epoca);
    return data.toLocaleDateString();
}
