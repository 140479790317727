function Comboformacao(props) {
    return (
        <>
        <label htmlFor={"cmb"+props.label.trim()} className="txt-roxo">{props.label}</label>
        <select className="form-select" aria-label="Default select example" id={"cmb"+props.label.trim()}  value={props.value} onChange={(e) => props.setValue(e.target.value)}>
            <option value="0">Auxiliar de enfermagem</option>
            <option value="1">Técnico(a) de enfermagem</option>
            <option value="2">Enfermeiro(a)</option>
            <option value="3">Mais de uma</option>
        </select>
        </>
    );
}

export default Comboformacao;